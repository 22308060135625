<template>
  <div class="emojis">
    <!--<button type="button" v-on:click="visible = !visible" class="emoji-color">
      <svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
        <path
          fill="#ccd2dc"
          d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-80-216c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160 0c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm4 72.6c-20.8 25-51.5 39.4-84 39.4s-63.2-14.3-84-39.4c-8.5-10.2-23.7-11.5-33.8-3.1-10.2 8.5-11.5 23.6-3.1 33.8 30 36 74.1 56.6 120.9 56.6s90.9-20.6 120.9-56.6c8.5-10.2 7.1-25.3-3.1-33.8-10.1-8.4-25.3-7.1-33.8 3.1z"
        />
      </svg>
      {{title}}
    </button>-->
    <div class="popup-box-container">
      <button type="button" @click="toogle" :disabled="disabled">
        <span :class="!setClass ? 'badge badge-primary' : setClass">
          <!-- <i :class="!icon ? 'fas fa-smile color-white' : icon"></i> -->
          <span class="material-symbols-outlined">{{icon}}</span>
          {{ title }}
        </span>
      </button>
    </div>

    <div
      :class="{
        active: visible,
        emojis__widget: true,
        __left: x === 'left',
        __right: x === 'right',
        __top: y === 'top',
        __bottom: y === 'bottom',
      }"
      :style="{
        left: (typeof left === 'string') ? left : '',
        right: (typeof right === 'string') ? right : '',
        top: (typeof top === 'string') ? top : '',
        bottom: (typeof bottom === 'string') ? bottom : '',
      }"
    >
      <header>
        <input
          type="text"
          :placeholder="$t('emoji-component.placeholder')"
          v-on:keyup="search"
          v-model="query"
        />
      </header>
      <!-- Emojis recentes -->
      <div class="emojis__category">
        <button
          type="button"
          tabindex="-1"
          :title="$t('emoji-component.recents')"
          v-on:click="group = -1"
          :class="{ active: group === -1 }"
        >
          <svg
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M504 255.531c.253 136.64-111.18 248.372-247.82 248.468-59.015.042-113.223-20.53-155.822-54.911-11.077-8.94-11.905-25.541-1.839-35.607l11.267-11.267c8.609-8.609 22.353-9.551 31.891-1.984C173.062 425.135 212.781 440 256 440c101.705 0 184-82.311 184-184 0-101.705-82.311-184-184-184-48.814 0-93.149 18.969-126.068 49.932l50.754 50.754c10.08 10.08 2.941 27.314-11.313 27.314H24c-8.837 0-16-7.163-16-16V38.627c0-14.254 17.234-21.393 27.314-11.314l49.372 49.372C129.209 34.136 189.552 8 256 8c136.81 0 247.747 110.78 248 247.531zm-180.912 78.784l9.823-12.63c8.138-10.463 6.253-25.542-4.21-33.679L288 256.349V152c0-13.255-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24v135.651l65.409 50.874c10.463 8.137 25.541 6.253 33.679-4.21z"
            />
          </svg>
        </button>
        <!-- Smileys & Emoções -->
        <button
          type="button"
          tabindex="-1"
          :title="$t('emoji-component.smileys')"
          v-on:click="group = 0"
          :class="{ active: group === 0 }"
        >
          <svg
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 496 512"
          >
            <path
              fill="currentColor"
              d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-80-216c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160 0c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm4 72.6c-20.8 25-51.5 39.4-84 39.4s-63.2-14.3-84-39.4c-8.5-10.2-23.7-11.5-33.8-3.1-10.2 8.5-11.5 23.6-3.1 33.8 30 36 74.1 56.6 120.9 56.6s90.9-20.6 120.9-56.6c8.5-10.2 7.1-25.3-3.1-33.8-10.1-8.4-25.3-7.1-33.8 3.1z"
            />
          </svg>
        </button>
        <button
          type="button"
          tabindex="-1"
          title="People &amp; Body"
          v-on:click="group = 1"
          :class="{ active: group === 1 }"
        >
          <svg
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
            />
          </svg>
        </button>
        <button
          type="button"
          tabindex="-1"
          title="Animals &amp; Nature"
          v-on:click="group = 2"
          :class="{ active: group === 2 }"
        >
          <svg
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M290.59 192c-20.18 0-106.82 1.98-162.59 85.95V192c0-52.94-43.06-96-96-96-17.67 0-32 14.33-32 32s14.33 32 32 32c17.64 0 32 14.36 32 32v256c0 35.3 28.7 64 64 64h176c8.84 0 16-7.16 16-16v-16c0-17.67-14.33-32-32-32h-32l128-96v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V289.86c-10.29 2.67-20.89 4.54-32 4.54-61.81 0-113.52-44.05-125.41-102.4zM448 96h-64l-64-64v134.4c0 53.02 42.98 96 96 96s96-42.98 96-96V32l-64 64zm-72 80c-8.84 0-16-7.16-16-16s7.16-16 16-16 16 7.16 16 16-7.16 16-16 16zm80 0c-8.84 0-16-7.16-16-16s7.16-16 16-16 16 7.16 16 16-7.16 16-16 16z"
            />
          </svg>
        </button>
        <button
          type="button"
          tabindex="-1"
          title="Food &amp; Drink"
          v-on:click="group = 3"
          :class="{ active: group === 3 }"
        >
          <svg
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
          >
            <path
              fill="currentColor"
              d="M192 384h192c53 0 96-43 96-96h32c70.6 0 128-57.4 128-128S582.6 32 512 32H120c-13.3 0-24 10.7-24 24v232c0 53 43 96 96 96zM512 96c35.3 0 64 28.7 64 64s-28.7 64-64 64h-32V96h32zm47.7 384H48.3c-47.6 0-61-64-36-64h583.3c25 0 11.8 64-35.9 64z"
            />
          </svg>
        </button>
        <button
          type="button"
          tabindex="-1"
          title="Activities"
          v-on:click="group = 4"
          :class="{ active: group === 4 }"
        >
          <svg
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zm-48 0l-.003-.282-26.064 22.741-62.679-58.5 16.454-84.355 34.303 3.072c-24.889-34.216-60.004-60.089-100.709-73.141l13.651 31.939L256 139l-74.953-41.525 13.651-31.939c-40.631 13.028-75.78 38.87-100.709 73.141l34.565-3.073 16.192 84.355-62.678 58.5-26.064-22.741-.003.282c0 43.015 13.497 83.952 38.472 117.991l7.704-33.897 85.138 10.447 36.301 77.826-29.902 17.786c40.202 13.122 84.29 13.148 124.572 0l-29.902-17.786 36.301-77.826 85.138-10.447 7.704 33.897C442.503 339.952 456 299.015 456 256zm-248.102 69.571l-29.894-91.312L256 177.732l77.996 56.527-29.622 91.312h-96.476z"
            />
          </svg>
        </button>
        <button
          type="button"
          tabindex="0"
          title="Travel &amp; Places"
          v-on:click="group = 5"
          :class="{ active: group === 5 }"
        >
          <svg
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M128 148v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm140 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm-128 96h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm128 0h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm-76 84v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm76 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm180 124v36H0v-36c0-6.6 5.4-12 12-12h19.5V24c0-13.3 10.7-24 24-24h337c13.3 0 24 10.7 24 24v440H436c6.6 0 12 5.4 12 12zM79.5 463H192v-67c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v67h112.5V49L80 48l-.5 415z"
            />
          </svg>
        </button>
        <button
          type="button"
          tabindex="-1"
          title="Objects"
          v-on:click="group = 6"
          :class="{ active: group === 6 }"
        >
          <svg
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 352 512"
          >
            <path
              fill="currentColor"
              d="M176 80c-52.94 0-96 43.06-96 96 0 8.84 7.16 16 16 16s16-7.16 16-16c0-35.3 28.72-64 64-64 8.84 0 16-7.16 16-16s-7.16-16-16-16zM96.06 459.17c0 3.15.93 6.22 2.68 8.84l24.51 36.84c2.97 4.46 7.97 7.14 13.32 7.14h78.85c5.36 0 10.36-2.68 13.32-7.14l24.51-36.84c1.74-2.62 2.67-5.7 2.68-8.84l.05-43.18H96.02l.04 43.18zM176 0C73.72 0 0 82.97 0 176c0 44.37 16.45 84.85 43.56 115.78 16.64 18.99 42.74 58.8 52.42 92.16v.06h48v-.12c-.01-4.77-.72-9.51-2.15-14.07-5.59-17.81-22.82-64.77-62.17-109.67-20.54-23.43-31.52-53.15-31.61-84.14-.2-73.64 59.67-128 127.95-128 70.58 0 128 57.42 128 128 0 30.97-11.24 60.85-31.65 84.14-39.11 44.61-56.42 91.47-62.1 109.46a47.507 47.507 0 0 0-2.22 14.3v.1h48v-.05c9.68-33.37 35.78-73.18 52.42-92.16C335.55 260.85 352 220.37 352 176 352 78.8 273.2 0 176 0z"
            />
          </svg>
        </button>
        <button
          type="button"
          tabindex="-1"
          title="Symbols"
          v-on:click="group = 7"
          :class="{ active: group === 7 }"
        >
          <svg
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M511.99 32.01c0-21.71-21.1-37.01-41.6-30.51L150.4 96c-13.3 4.2-22.4 16.5-22.4 30.5v261.42c-10.05-2.38-20.72-3.92-32-3.92-53.02 0-96 28.65-96 64s42.98 64 96 64 96-28.65 96-64V214.31l256-75.02v184.63c-10.05-2.38-20.72-3.92-32-3.92-53.02 0-96 28.65-96 64s42.98 64 96 64 96-28.65 96-64l-.01-351.99z"
            />
          </svg>
        </button>
        <button
          type="button"
          tabindex="-1"
          title="Flags"
          v-on:click="group = 8"
          :class="{ active: group === 8 }"
        >
          <svg
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M336.174 80c-49.132 0-93.305-32-161.913-32-31.301 0-58.303 6.482-80.721 15.168a48.04 48.04 0 0 0 2.142-20.727C93.067 19.575 74.167 1.594 51.201.104 23.242-1.71 0 20.431 0 48c0 17.764 9.657 33.262 24 41.562V496c0 8.837 7.163 16 16 16h16c8.837 0 16-7.163 16-16v-83.443C109.869 395.28 143.259 384 199.826 384c49.132 0 93.305 32 161.913 32 58.479 0 101.972-22.617 128.548-39.981C503.846 367.161 512 352.051 512 335.855V95.937c0-34.459-35.264-57.768-66.904-44.117C409.193 67.309 371.641 80 336.174 80zM464 336c-21.783 15.412-60.824 32-102.261 32-59.945 0-102.002-32-161.913-32-43.361 0-96.379 9.403-127.826 24V128c21.784-15.412 60.824-32 102.261-32 59.945 0 102.002 32 161.913 32 43.271 0 96.32-17.366 127.826-32v240z"
            />
          </svg>
        </button>
      </div>

      <div class="emojis__area">
        <div :class="{ active: query !== '', emojis__container: true }">
          <h3 v-if="!searching && results.length === 0">
            Não encontramos emoji
          </h3>
          <h3 v-if="searching && results.length === 0">Buscando...</h3>

          <button
            type="button"
            tabindex="-1"
            v-for="emoji in results"
            :key="emoji.title"
            :title="emoji.title"
            v-on:mouseover="details(emoji, false)"
            v-on:click="details(emoji, true)"
          >
            {{ emoji.data }}
          </button>
        </div>

        <div
          :class="{
            active: group === -1 && query === '',
            emojis__container: true,
          }"
        >
          <h3 v-if="history.length === 0">Não há emojis recentes</h3>

          <button
            type="button"
            tabindex="-1"
            v-for="(emoji, index) in history"
            :key="`${index}:${emoji.title}`"
            :title="emoji.title"
            v-on:mouseover="details(emoji, false)"
            v-on:click="details(emoji, true)"
          >
            {{ emoji.data }}
          </button>
        </div>

        <div
          v-for="(emojigroup, index) in emojis"
          :key="index"
          :class="{
            active: group === index && query === '',
            emojis__container: true,
          }"
        >
          <button
            type="button"
            tabindex="-1"
            v-for="emoji in emojigroup"
            :key="emoji.title"
            :title="emoji.title"
            v-on:mouseover="details(emoji, false)"
            v-on:click="details(emoji, true)"
          >
            {{ emoji.data }}
          </button>
        </div>
      </div>

      <footer>
        <div v-if="last">
          <input :value="last.data" readonly> {{ last.title }}
        </div>
      </footer>
    </div>

    <div
      :class="{ active: visible, emojis__overlay: true }"
      v-on:click="close"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'Emojis',
  props: {
    title: String,
    setClass: String,
    icon: String,
    x: String,
    y: Number,
    top: String,
    bottom: String,
    left: String,
    right: String,
    disabled: Boolean,
  },
  mounted() {
    let history = localStorage.historyEmojis;

    if (history) {
      history = JSON.parse(history);

      const repeated = [];

      history = history.filter((a, b) => {
        const emoji = history[b].data;

        if (repeated.indexOf(emoji) !== -1) return false;

        repeated.push(emoji);
        return true;
      });

      if (history && history.length) {
        this.group = -1;
        this.history = history;
      }
    }

    history = null;
  },
  data() {
    return {
      visible: false,
      group: 0,
      history: [],
      last: null,
      timeout: 0,
      query: '',
      searching: false,
      results: [],
      emojis: [
        [
          { title: 'grinning face', data: '😀' },
          { title: 'grinning face with big eyes', data: '😃' },
          { title: 'grinning face with smiling eyes', data: '😄' },
          { title: 'beaming face with smiling eyes', data: '😁' },
          { title: 'grinning squinting face', data: '😆' },
          { title: 'grinning face with sweat', data: '😅' },
          { title: 'rolling on the floor laughing', data: '🤣' },
          { title: 'face with tears of joy', data: '😂' },
          { title: 'slightly smiling face', data: '🙂' },
          { title: 'upside-down face', data: '🙃' },
          { title: 'winking face', data: '😉' },
          { title: 'smiling face with smiling eyes', data: '😊' },
          { title: 'smiling face with halo', data: '😇' },
          { title: 'smiling face with hearts', data: '🥰' },
          { title: 'smiling face with heart-eyes', data: '😍' },
          { title: 'star-struck', data: '🤩' },
          { title: 'face blowing a kiss', data: '😘' },
          { title: 'kissing face', data: '😗' },
          { title: 'smiling face', data: '☺️' },
          { title: 'kissing face with closed eyes', data: '😚' },
          { title: 'kissing face with smiling eyes', data: '😙' },
          { title: 'face savoring food', data: '😋' },
          { title: 'face with tongue', data: '😛' },
          { title: 'winking face with tongue', data: '😜' },
          { title: 'zany face', data: '🤪' },
          { title: 'squinting face with tongue', data: '😝' },
          { title: 'money-mouth face', data: '🤑' },
          { title: 'hugging face', data: '🤗' },
          { title: 'face with hand over mouth', data: '🤭' },
          { title: 'shushing face', data: '🤫' },
          { title: 'thinking face', data: '🤔' },
          { title: 'zipper-mouth face', data: '🤐' },
          { title: 'face with raised eyebrow', data: '🤨' },
          { title: 'neutral face', data: '😐' },
          { title: 'expressionless face', data: '😑' },
          { title: 'face without mouth', data: '😶' },
          { title: 'smirking face', data: '😏' },
          { title: 'unamused face', data: '😒' },
          { title: 'face with rolling eyes', data: '🙄' },
          { title: 'grimacing face', data: '😬' },
          { title: 'lying face', data: '🤥' },
          { title: 'relieved face', data: '😌' },
          { title: 'pensive face', data: '😔' },
          { title: 'sleepy face', data: '😪' },
          { title: 'drooling face', data: '🤤' },
          { title: 'sleeping face', data: '😴' },
          { title: 'face with medical mask', data: '😷' },
          { title: 'face with thermometer', data: '🤒' },
          { title: 'face with head-bandage', data: '🤕' },
          { title: 'nauseated face', data: '🤢' },
          { title: 'face vomiting', data: '🤮' },
          { title: 'sneezing face', data: '🤧' },
          { title: 'hot face', data: '🥵' },
          { title: 'cold face', data: '🥶' },
          { title: 'woozy face', data: '🥴' },
          { title: 'dizzy face', data: '😵' },
          { title: 'exploding head', data: '🤯' },
          { title: 'cowboy hat face', data: '🤠' },
          { title: 'partying face', data: '🥳' },
          { title: 'smiling face with sunglasses', data: '😎' },
          { title: 'nerd face', data: '🤓' },
          { title: 'face with monocle', data: '🧐' },
          { title: 'confused face', data: '😕' },
          { title: 'worried face', data: '😟' },
          { title: 'slightly frowning face', data: '🙁' },
          { title: 'frowning face', data: '☹️' },
          { title: 'face with open mouth', data: '😮' },
          { title: 'hushed face', data: '😯' },
          { title: 'astonished face', data: '😲' },
          { title: 'flushed face', data: '😳' },
          { title: 'pleading face', data: '🥺' },
          { title: 'frowning face with open mouth', data: '😦' },
          { title: 'anguished face', data: '😧' },
          { title: 'fearful face', data: '😨' },
          { title: 'anxious face with sweat', data: '😰' },
          { title: 'sad but relieved face', data: '😥' },
          { title: 'crying face', data: '😢' },
          { title: 'loudly crying face', data: '😭' },
          { title: 'face screaming in fear', data: '😱' },
          { title: 'confounded face', data: '😖' },
          { title: 'persevering face', data: '😣' },
          { title: 'disappointed face', data: '😞' },
          { title: 'downcast face with sweat', data: '😓' },
          { title: 'weary face', data: '😩' },
          { title: 'tired face', data: '😫' },
          { title: 'yawning face', data: '🥱' },
          { title: 'face with steam from nose', data: '😤' },
          { title: 'pouting face', data: '😡' },
          { title: 'angry face', data: '😠' },
          { title: 'face with symbols on mouth', data: '🤬' },
          { title: 'smiling face with horns', data: '😈' },
          { title: 'angry face with horns', data: '👿' },
          { title: 'skull', data: '💀' },
          { title: 'skull and crossbones', data: '☠️' },
          { title: 'pile of poo', data: '💩' },
          { title: 'clown face', data: '🤡' },
          { title: 'ogre', data: '👹' },
          { title: 'goblin', data: '👺' },
          { title: 'ghost', data: '👻' },
          { title: 'alien', data: '👽' },
          { title: 'alien monster', data: '👾' },
          { title: 'robot', data: '🤖' },
          { title: 'grinning cat', data: '😺' },
          { title: 'grinning cat with smiling eyes', data: '😸' },
          { title: 'cat with tears of joy', data: '😹' },
          { title: 'smiling cat with heart-eyes', data: '😻' },
          { title: 'cat with wry smile', data: '😼' },
          { title: 'kissing cat', data: '😽' },
          { title: 'weary cat', data: '🙀' },
          { title: 'crying cat', data: '😿' },
          { title: 'pouting cat', data: '😾' },
          { title: 'see-no-evil monkey', data: '🙈' },
          { title: 'hear-no-evil monkey', data: '🙉' },
          { title: 'speak-no-evil monkey', data: '🙊' },
          { title: 'kiss mark', data: '💋' },
          { title: 'love letter', data: '💌' },
          { title: 'heart with arrow', data: '💘' },
          { title: 'heart with ribbon', data: '💝' },
          { title: 'sparkling heart', data: '💖' },
          { title: 'growing heart', data: '💗' },
          { title: 'beating heart', data: '💓' },
          { title: 'revolving hearts', data: '💞' },
          { title: 'two hearts', data: '💕' },
          { title: 'heart decoration', data: '💟' },
          { title: 'heart exclamation', data: '❣️' },
          { title: 'broken heart', data: '💔' },
          { title: 'red heart', data: '❤️' },
          { title: 'orange heart', data: '🧡' },
          { title: 'yellow heart', data: '💛' },
          { title: 'green heart', data: '💚' },
          { title: 'blue heart', data: '💙' },
          { title: 'purple heart', data: '💜' },
          { title: 'brown heart', data: '🤎' },
          { title: 'black heart', data: '🖤' },
          { title: 'white heart', data: '🤍' },
          { title: 'hundred points', data: '💯' },
          { title: 'anger symbol', data: '💢' },
          { title: 'collision', data: '💥' },
          { title: 'dizzy', data: '💫' },
          { title: 'sweat droplets', data: '💦' },
          { title: 'dashing away', data: '💨' },
          { title: 'hole', data: '🕳️' },
          { title: 'bomb', data: '💣' },
          { title: 'speech balloon', data: '💬' },
          { title: 'eye in speech bubble', data: '👁️‍🗨️' },
          { title: 'left speech bubble', data: '🗨️' },
          { title: 'right anger bubble', data: '🗯️' },
          { title: 'thought balloon', data: '💭' },
          { title: 'zzz', data: '💤' },
        ],
        [
          { title: 'waving hand', data: '👋' },
          { title: 'raised back of hand', data: '🤚' },
          { title: 'hand with fingers splayed', data: '🖐️' },
          { title: 'raised hand', data: '✋' },
          { title: 'vulcan salute', data: '🖖' },
          { title: 'OK hand', data: '👌' },
          { title: 'pinching hand', data: '🤏' },
          { title: 'victory hand', data: '✌️' },
          { title: 'crossed fingers', data: '🤞' },
          { title: 'love-you gesture', data: '🤟' },
          { title: 'sign of the horns', data: '🤘' },
          { title: 'call me hand', data: '🤙' },
          { title: 'backhand index pointing left', data: '👈' },
          { title: 'backhand index pointing right', data: '👉' },
          { title: 'backhand index pointing up', data: '👆' },
          { title: 'middle finger', data: '🖕' },
          { title: 'backhand index pointing down', data: '👇' },
          { title: 'index pointing up', data: '☝️' },
          { title: 'thumbs up', data: '👍' },
          { title: 'thumbs down', data: '👎' },
          { title: 'raised fist', data: '✊' },
          { title: 'oncoming fist', data: '👊' },
          { title: 'left-facing fist', data: '🤛' },
          { title: 'right-facing fist', data: '🤜' },
          { title: 'clapping hands', data: '👏' },
          { title: 'raising hands', data: '🙌' },
          { title: 'open hands', data: '👐' },
          { title: 'palms up together', data: '🤲' },
          { title: 'handshake', data: '🤝' },
          { title: 'folded hands', data: '🙏' },
          { title: 'writing hand', data: '✍️' },
          { title: 'nail polish', data: '💅' },
          { title: 'selfie', data: '🤳' },
          { title: 'flexed biceps', data: '💪' },
          { title: 'mechanical arm', data: '🦾' },
          { title: 'mechanical leg', data: '🦿' },
          { title: 'leg', data: '🦵' },
          { title: 'foot', data: '🦶' },
          { title: 'ear', data: '👂' },
          { title: 'ear with hearing aid', data: '🦻' },
          { title: 'nose', data: '👃' },
          { title: 'brain', data: '🧠' },
          { title: 'tooth', data: '🦷' },
          { title: 'bone', data: '🦴' },
          { title: 'eyes', data: '👀' },
          { title: 'eye', data: '👁️' },
          { title: 'tongue', data: '👅' },
          { title: 'mouth', data: '👄' },
          { title: 'baby', data: '👶' },
          { title: 'child', data: '🧒' },
          { title: 'boy', data: '👦' },
          { title: 'girl', data: '👧' },
          { title: 'person', data: '🧑' },
          { title: 'person with blond hair', data: '👱' },
          { title: 'man', data: '👨' },
          { title: 'man with beard', data: '🧔' },
          { title: 'man with red hair', data: '👨‍🦰' },
          { title: 'man with curly hair', data: '👨‍🦱' },
          { title: 'man with white hair', data: '👨‍🦳' },
          { title: 'man with no hair', data: '👨‍🦲' },
          { title: 'woman', data: '👩' },
          { title: 'woman with red hair', data: '👩‍🦰' },
          { title: 'person with red hair', data: '🧑‍🦰' },
          { title: 'woman with curly hair', data: '👩‍🦱' },
          { title: 'person with curly hair', data: '🧑‍🦱' },
          { title: 'woman with white hair', data: '👩‍🦳' },
          { title: 'person with white hair', data: '🧑‍🦳' },
          { title: 'woman with no hair', data: '👩‍🦲' },
          { title: 'person with no hair', data: '🧑‍🦲' },
          { title: 'woman with blond hair', data: '👱‍♀️' },
          { title: 'man with blond hair', data: '👱‍♂️' },
          { title: 'older person', data: '🧓' },
          { title: 'old man', data: '👴' },
          { title: 'old woman', data: '👵' },
          { title: 'person frowning', data: '🙍' },
          { title: 'man frowning', data: '🙍‍♂️' },
          { title: 'woman frowning', data: '🙍‍♀️' },
          { title: 'person pouting', data: '🙎' },
          { title: 'man pouting', data: '🙎‍♂️' },
          { title: 'woman pouting', data: '🙎‍♀️' },
          { title: 'person gesturing NO', data: '🙅' },
          { title: 'man gesturing NO', data: '🙅‍♂️' },
          { title: 'woman gesturing NO', data: '🙅‍♀️' },
          { title: 'person gesturing OK', data: '🙆' },
          { title: 'man gesturing OK', data: '🙆‍♂️' },
          { title: 'woman gesturing OK', data: '🙆‍♀️' },
          { title: 'person tipping hand', data: '💁' },
          { title: 'man tipping hand', data: '💁‍♂️' },
          { title: 'woman tipping hand', data: '💁‍♀️' },
          { title: 'person raising hand', data: '🙋' },
          { title: 'man raising hand', data: '🙋‍♂️' },
          { title: 'woman raising hand', data: '🙋‍♀️' },
          { title: 'deaf person', data: '🧏' },
          { title: 'deaf man', data: '🧏‍♂️' },
          { title: 'deaf woman', data: '🧏‍♀️' },
          { title: 'person bowing', data: '🙇' },
          { title: 'man bowing', data: '🙇‍♂️' },
          { title: 'woman bowing', data: '🙇‍♀️' },
          { title: 'person facepalming', data: '🤦' },
          { title: 'man facepalming', data: '🤦‍♂️' },
          { title: 'woman facepalming', data: '🤦‍♀️' },
          { title: 'person shrugging', data: '🤷' },
          { title: 'man shrugging', data: '🤷‍♂️' },
          { title: 'woman shrugging', data: '🤷‍♀️' },
          { title: 'health worker', data: '🧑‍⚕️' },
          { title: 'man health worker', data: '👨‍⚕️' },
          { title: 'woman health worker', data: '👩‍⚕️' },
          { title: 'student', data: '🧑‍🎓' },
          { title: 'man student', data: '👨‍🎓' },
          { title: 'woman student', data: '👩‍🎓' },
          { title: 'teacher', data: '🧑‍🏫' },
          { title: 'man teacher', data: '👨‍🏫' },
          { title: 'woman teacher', data: '👩‍🏫' },
          { title: 'judge', data: '🧑‍⚖️' },
          { title: 'man judge', data: '👨‍⚖️' },
          { title: 'woman judge', data: '👩‍⚖️' },
          { title: 'farmer', data: '🧑‍🌾' },
          { title: 'man farmer', data: '👨‍🌾' },
          { title: 'woman farmer', data: '👩‍🌾' },
          { title: 'cook', data: '🧑‍🍳' },
          { title: 'man cook', data: '👨‍🍳' },
          { title: 'woman cook', data: '👩‍🍳' },
          { title: 'mechanic', data: '🧑‍🔧' },
          { title: 'man mechanic', data: '👨‍🔧' },
          { title: 'woman mechanic', data: '👩‍🔧' },
          { title: 'factory worker', data: '🧑‍🏭' },
          { title: 'man factory worker', data: '👨‍🏭' },
          { title: 'woman factory worker', data: '👩‍🏭' },
          { title: 'office worker', data: '🧑‍💼' },
          { title: 'man office worker', data: '👨‍💼' },
          { title: 'woman office worker', data: '👩‍💼' },
          { title: 'scientist', data: '🧑‍🔬' },
          { title: 'man scientist', data: '👨‍🔬' },
          { title: 'woman scientist', data: '👩‍🔬' },
          { title: 'technologist', data: '🧑‍💻' },
          { title: 'man technologist', data: '👨‍💻' },
          { title: 'woman technologist', data: '👩‍💻' },
          { title: 'singer', data: '🧑‍🎤' },
          { title: 'man singer', data: '👨‍🎤' },
          { title: 'woman singer', data: '👩‍🎤' },
          { title: 'artist', data: '🧑‍🎨' },
          { title: 'man artist', data: '👨‍🎨' },
          { title: 'woman artist', data: '👩‍🎨' },
          { title: 'pilot', data: '🧑‍✈️' },
          { title: 'man pilot', data: '👨‍✈️' },
          { title: 'woman pilot', data: '👩‍✈️' },
          { title: 'astronaut', data: '🧑‍🚀' },
          { title: 'man astronaut', data: '👨‍🚀' },
          { title: 'woman astronaut', data: '👩‍🚀' },
          { title: 'firefighter', data: '🧑‍🚒' },
          { title: 'man firefighter', data: '👨‍🚒' },
          { title: 'woman firefighter', data: '👩‍🚒' },
          { title: 'police officer', data: '👮' },
          { title: 'man police officer', data: '👮‍♂️' },
          { title: 'woman police officer', data: '👮‍♀️' },
          { title: 'detective', data: '🕵️' },
          { title: 'man detective', data: '🕵️‍♂️' },
          { title: 'woman detective', data: '🕵️‍♀️' },
          { title: 'guard', data: '💂' },
          { title: 'man guard', data: '💂‍♂️' },
          { title: 'woman guard', data: '💂‍♀️' },
          { title: 'construction worker', data: '👷' },
          { title: 'man construction worker', data: '👷‍♂️' },
          { title: 'woman construction worker', data: '👷‍♀️' },
          { title: 'prince', data: '🤴' },
          { title: 'princess', data: '👸' },
          { title: 'person wearing turban', data: '👳' },
          { title: 'man wearing turban', data: '👳‍♂️' },
          { title: 'woman wearing turban', data: '👳‍♀️' },
          { title: 'person with skullcap', data: '👲' },
          { title: 'woman with headscarf', data: '🧕' },
          { title: 'person in tuxedo', data: '🤵' },
          { title: 'person with veil', data: '👰' },
          { title: 'pregnant woman', data: '🤰' },
          { title: 'breast-feeding', data: '🤱' },
          { title: 'baby angel', data: '👼' },
          { title: 'Santa Claus', data: '🎅' },
          { title: 'Mrs. Claus', data: '🤶' },
          { title: 'superhero', data: '🦸' },
          { title: 'man superhero', data: '🦸‍♂️' },
          { title: 'woman superhero', data: '🦸‍♀️' },
          { title: 'supervillain', data: '🦹' },
          { title: 'man supervillain', data: '🦹‍♂️' },
          { title: 'woman supervillain', data: '🦹‍♀️' },
          { title: 'mage', data: '🧙' },
          { title: 'man mage', data: '🧙‍♂️' },
          { title: 'woman mage', data: '🧙‍♀️' },
          { title: 'fairy', data: '🧚' },
          { title: 'man fairy', data: '🧚‍♂️' },
          { title: 'woman fairy', data: '🧚‍♀️' },
          { title: 'vampire', data: '🧛' },
          { title: 'man vampire', data: '🧛‍♂️' },
          { title: 'woman vampire', data: '🧛‍♀️' },
          { title: 'merperson', data: '🧜' },
          { title: 'merman', data: '🧜‍♂️' },
          { title: 'mermaid', data: '🧜‍♀️' },
          { title: 'elf', data: '🧝' },
          { title: 'man elf', data: '🧝‍♂️' },
          { title: 'woman elf', data: '🧝‍♀️' },
          { title: 'genie', data: '🧞' },
          { title: 'man genie', data: '🧞‍♂️' },
          { title: 'woman genie', data: '🧞‍♀️' },
          { title: 'zombie', data: '🧟' },
          { title: 'man zombie', data: '🧟‍♂️' },
          { title: 'woman zombie', data: '🧟‍♀️' },
          { title: 'person getting massage', data: '💆' },
          { title: 'man getting massage', data: '💆‍♂️' },
          { title: 'woman getting massage', data: '💆‍♀️' },
          { title: 'person getting haircut', data: '💇' },
          { title: 'man getting haircut', data: '💇‍♂️' },
          { title: 'woman getting haircut', data: '💇‍♀️' },
          { title: 'person walking', data: '🚶' },
          { title: 'man walking', data: '🚶‍♂️' },
          { title: 'woman walking', data: '🚶‍♀️' },
          { title: 'person standing', data: '🧍' },
          { title: 'man standing', data: '🧍‍♂️' },
          { title: 'woman standing', data: '🧍‍♀️' },
          { title: 'person kneeling', data: '🧎' },
          { title: 'man kneeling', data: '🧎‍♂️' },
          { title: 'woman kneeling', data: '🧎‍♀️' },
          { title: 'person with white cane', data: '🧑‍🦯' },
          { title: 'man with white cane', data: '👨‍🦯' },
          { title: 'woman with white cane', data: '👩‍🦯' },
          { title: 'person in motorized wheelchair', data: '🧑‍🦼' },
          { title: 'man in motorized wheelchair', data: '👨‍🦼' },
          { title: 'woman in motorized wheelchair', data: '👩‍🦼' },
          { title: 'person in manual wheelchair', data: '🧑‍🦽' },
          { title: 'man in manual wheelchair', data: '👨‍🦽' },
          { title: 'woman in manual wheelchair', data: '👩‍🦽' },
          { title: 'person running', data: '🏃' },
          { title: 'man running', data: '🏃‍♂️' },
          { title: 'woman running', data: '🏃‍♀️' },
          { title: 'woman dancing', data: '💃' },
          { title: 'man dancing', data: '🕺' },
          { title: 'person in suit levitating', data: '🕴️' },
          { title: 'people with bunny ears', data: '👯' },
          { title: 'men with bunny ears', data: '👯‍♂️' },
          { title: 'women with bunny ears', data: '👯‍♀️' },
          { title: 'person in steamy room', data: '🧖' },
          { title: 'man in steamy room', data: '🧖‍♂️' },
          { title: 'woman in steamy room', data: '🧖‍♀️' },
          { title: 'person climbing', data: '🧗' },
          { title: 'man climbing', data: '🧗‍♂️' },
          { title: 'woman climbing', data: '🧗‍♀️' },
          { title: 'person fencing', data: '🤺' },
          { title: 'horse racing', data: '🏇' },
          { title: 'skier', data: '⛷️' },
          { title: 'snowboarder', data: '🏂' },
          { title: 'person golfing', data: '🏌️' },
          { title: 'man golfing', data: '🏌️‍♂️' },
          { title: 'woman golfing', data: '🏌️‍♀️' },
          { title: 'person surfing', data: '🏄' },
          { title: 'man surfing', data: '🏄‍♂️' },
          { title: 'woman surfing', data: '🏄‍♀️' },
          { title: 'person rowing boat', data: '🚣' },
          { title: 'man rowing boat', data: '🚣‍♂️' },
          { title: 'woman rowing boat', data: '🚣‍♀️' },
          { title: 'person swimming', data: '🏊' },
          { title: 'man swimming', data: '🏊‍♂️' },
          { title: 'woman swimming', data: '🏊‍♀️' },
          { title: 'person bouncing ball', data: '⛹️' },
          { title: 'man bouncing ball', data: '⛹️‍♂️' },
          { title: 'woman bouncing ball', data: '⛹️‍♀️' },
          { title: 'person lifting weights', data: '🏋️' },
          { title: 'man lifting weights', data: '🏋️‍♂️' },
          { title: 'woman lifting weights', data: '🏋️‍♀️' },
          { title: 'person biking', data: '🚴' },
          { title: 'man biking', data: '🚴‍♂️' },
          { title: 'woman biking', data: '🚴‍♀️' },
          { title: 'person mountain biking', data: '🚵' },
          { title: 'man mountain biking', data: '🚵‍♂️' },
          { title: 'woman mountain biking', data: '🚵‍♀️' },
          { title: 'person cartwheeling', data: '🤸' },
          { title: 'man cartwheeling', data: '🤸‍♂️' },
          { title: 'woman cartwheeling', data: '🤸‍♀️' },
          { title: 'people wrestling', data: '🤼' },
          { title: 'men wrestling', data: '🤼‍♂️' },
          { title: 'women wrestling', data: '🤼‍♀️' },
          { title: 'person playing water polo', data: '🤽' },
          { title: 'man playing water polo', data: '🤽‍♂️' },
          { title: 'woman playing water polo', data: '🤽‍♀️' },
          { title: 'person playing handball', data: '🤾' },
          { title: 'man playing handball', data: '🤾‍♂️' },
          { title: 'woman playing handball', data: '🤾‍♀️' },
          { title: 'person juggling', data: '🤹' },
          { title: 'man juggling', data: '🤹‍♂️' },
          { title: 'woman juggling', data: '🤹‍♀️' },
          { title: 'person in lotus position', data: '🧘' },
          { title: 'man in lotus position', data: '🧘‍♂️' },
          { title: 'woman in lotus position', data: '🧘‍♀️' },
          { title: 'person taking bath', data: '🛀' },
          { title: 'person in bed', data: '🛌' },
          { title: 'people holding hands', data: '🧑‍🤝‍🧑' },
          { title: 'women holding hands', data: '👭' },
          { title: 'woman and man holding hands', data: '👫' },
          { title: 'men holding hands', data: '👬' },
          { title: 'kiss', data: '💏' },
          { title: 'couple with heart', data: '💑' },
          { title: 'family', data: '👪' },
          { title: 'family: man, woman, boy', data: '👨‍👩‍👦' },
          { title: 'family: man, woman, girl', data: '👨‍👩‍👧' },
          { title: 'family: man, woman, girl, boy', data: '👨‍👩‍👧‍👦' },
          { title: 'family: man, woman, boy, boy', data: '👨‍👩‍👦‍👦' },
          { title: 'family: man, woman, girl, girl', data: '👨‍👩‍👧‍👧' },
          { title: 'family: man, man, boy', data: '👨‍👨‍👦' },
          { title: 'family: man, man, girl', data: '👨‍👨‍👧' },
          { title: 'family: man, man, girl, boy', data: '👨‍👨‍👧‍👦' },
          { title: 'family: man, man, boy, boy', data: '👨‍👨‍👦‍👦' },
          { title: 'family: man, man, girl, girl', data: '👨‍👨‍👧‍👧' },
          { title: 'family: woman, woman, boy', data: '👩‍👩‍👦' },
          { title: 'family: woman, woman, girl', data: '👩‍👩‍👧' },
          { title: 'family: woman, woman, girl, boy', data: '👩‍👩‍👧‍👦' },
          { title: 'family: woman, woman, boy, boy', data: '👩‍👩‍👦‍👦' },
          { title: 'family: woman, woman, girl, girl', data: '👩‍👩‍👧‍👧' },
          { title: 'family: man, boy', data: '👨‍👦' },
          { title: 'family: man, boy, boy', data: '👨‍👦‍👦' },
          { title: 'family: man, girl', data: '👨‍👧' },
          { title: 'family: man, girl, boy', data: '👨‍👧‍👦' },
          { title: 'family: man, girl, girl', data: '👨‍👧‍👧' },
          { title: 'family: woman, boy', data: '👩‍👦' },
          { title: 'family: woman, boy, boy', data: '👩‍👦‍👦' },
          { title: 'family: woman, girl', data: '👩‍👧' },
          { title: 'family: woman, girl, boy', data: '👩‍👧‍👦' },
          { title: 'family: woman, girl, girl', data: '👩‍👧‍👧' },
          { title: 'speaking head', data: '🗣️' },
          { title: 'bust in silhouette', data: '👤' },
          { title: 'busts in silhouette', data: '👥' },
          { title: 'footprints', data: '👣' },
        ],
        [
          { title: 'monkey face', data: '🐵' },
          { title: 'monkey', data: '🐒' },
          { title: 'gorilla', data: '🦍' },
          { title: 'orangutan', data: '🦧' },
          { title: 'dog face', data: '🐶' },
          { title: 'dog', data: '🐕' },
          { title: 'guide dog', data: '🦮' },
          { title: 'service dog', data: '🐕‍🦺' },
          { title: 'poodle', data: '🐩' },
          { title: 'wolf', data: '🐺' },
          { title: 'fox', data: '🦊' },
          { title: 'raccoon', data: '🦝' },
          { title: 'cat face', data: '🐱' },
          { title: 'cat', data: '🐈' },
          { title: 'lion', data: '🦁' },
          { title: 'tiger face', data: '🐯' },
          { title: 'tiger', data: '🐅' },
          { title: 'leopard', data: '🐆' },
          { title: 'horse face', data: '🐴' },
          { title: 'horse', data: '🐎' },
          { title: 'unicorn', data: '🦄' },
          { title: 'zebra', data: '🦓' },
          { title: 'deer', data: '🦌' },
          { title: 'cow face', data: '🐮' },
          { title: 'ox', data: '🐂' },
          { title: 'water buffalo', data: '🐃' },
          { title: 'cow', data: '🐄' },
          { title: 'pig face', data: '🐷' },
          { title: 'pig', data: '🐖' },
          { title: 'boar', data: '🐗' },
          { title: 'pig nose', data: '🐽' },
          { title: 'ram', data: '🐏' },
          { title: 'ewe', data: '🐑' },
          { title: 'goat', data: '🐐' },
          { title: 'camel', data: '🐪' },
          { title: 'two-hump camel', data: '🐫' },
          { title: 'llama', data: '🦙' },
          { title: 'giraffe', data: '🦒' },
          { title: 'elephant', data: '🐘' },
          { title: 'rhinoceros', data: '🦏' },
          { title: 'hippopotamus', data: '🦛' },
          { title: 'mouse face', data: '🐭' },
          { title: 'mouse', data: '🐁' },
          { title: 'rat', data: '🐀' },
          { title: 'hamster', data: '🐹' },
          { title: 'rabbit face', data: '🐰' },
          { title: 'rabbit', data: '🐇' },
          { title: 'chipmunk', data: '🐿️' },
          { title: 'hedgehog', data: '🦔' },
          { title: 'bat', data: '🦇' },
          { title: 'bear', data: '🐻' },
          { title: 'koala', data: '🐨' },
          { title: 'panda', data: '🐼' },
          { title: 'sloth', data: '🦥' },
          { title: 'otter', data: '🦦' },
          { title: 'skunk', data: '🦨' },
          { title: 'kangaroo', data: '🦘' },
          { title: 'badger', data: '🦡' },
          { title: 'paw prints', data: '🐾' },
          { title: 'turkey', data: '🦃' },
          { title: 'chicken', data: '🐔' },
          { title: 'rooster', data: '🐓' },
          { title: 'hatching chick', data: '🐣' },
          { title: 'baby chick', data: '🐤' },
          { title: 'front-facing baby chick', data: '🐥' },
          { title: 'bird', data: '🐦' },
          { title: 'penguin', data: '🐧' },
          { title: 'dove', data: '🕊️' },
          { title: 'eagle', data: '🦅' },
          { title: 'duck', data: '🦆' },
          { title: 'swan', data: '🦢' },
          { title: 'owl', data: '🦉' },
          { title: 'flamingo', data: '🦩' },
          { title: 'peacock', data: '🦚' },
          { title: 'parrot', data: '🦜' },
          { title: 'frog', data: '🐸' },
          { title: 'crocodile', data: '🐊' },
          { title: 'turtle', data: '🐢' },
          { title: 'lizard', data: '🦎' },
          { title: 'snake', data: '🐍' },
          { title: 'dragon face', data: '🐲' },
          { title: 'dragon', data: '🐉' },
          { title: 'sauropod', data: '🦕' },
          { title: 'T-Rex', data: '🦖' },
          { title: 'spouting whale', data: '🐳' },
          { title: 'whale', data: '🐋' },
          { title: 'dolphin', data: '🐬' },
          { title: 'fish', data: '🐟' },
          { title: 'tropical fish', data: '🐠' },
          { title: 'blowfish', data: '🐡' },
          { title: 'shark', data: '🦈' },
          { title: 'octopus', data: '🐙' },
          { title: 'spiral shell', data: '🐚' },
          { title: 'snail', data: '🐌' },
          { title: 'butterfly', data: '🦋' },
          { title: 'bug', data: '🐛' },
          { title: 'ant', data: '🐜' },
          { title: 'honeybee', data: '🐝' },
          { title: 'lady beetle', data: '🐞' },
          { title: 'cricket', data: '🦗' },
          { title: 'spider', data: '🕷️' },
          { title: 'spider web', data: '🕸️' },
          { title: 'scorpion', data: '🦂' },
          { title: 'mosquito', data: '🦟' },
          { title: 'microbe', data: '🦠' },
          { title: 'bouquet', data: '💐' },
          { title: 'cherry blossom', data: '🌸' },
          { title: 'white flower', data: '💮' },
          { title: 'rosette', data: '🏵️' },
          { title: 'rose', data: '🌹' },
          { title: 'wilted flower', data: '🥀' },
          { title: 'hibiscus', data: '🌺' },
          { title: 'sunflower', data: '🌻' },
          { title: 'blossom', data: '🌼' },
          { title: 'tulip', data: '🌷' },
          { title: 'seedling', data: '🌱' },
          { title: 'evergreen tree', data: '🌲' },
          { title: 'deciduous tree', data: '🌳' },
          { title: 'palm tree', data: '🌴' },
          { title: 'cactus', data: '🌵' },
          { title: 'sheaf of rice', data: '🌾' },
          { title: 'herb', data: '🌿' },
          { title: 'shamrock', data: '☘️' },
          { title: 'four leaf clover', data: '🍀' },
          { title: 'maple leaf', data: '🍁' },
          { title: 'fallen leaf', data: '🍂' },
          { title: 'leaf fluttering in wind', data: '🍃' },
        ],
        [
          { title: 'grapes', data: '🍇' },
          { title: 'melon', data: '🍈' },
          { title: 'watermelon', data: '🍉' },
          { title: 'tangerine', data: '🍊' },
          { title: 'lemon', data: '🍋' },
          { title: 'banana', data: '🍌' },
          { title: 'pineapple', data: '🍍' },
          { title: 'mango', data: '🥭' },
          { title: 'red apple', data: '🍎' },
          { title: 'green apple', data: '🍏' },
          { title: 'pear', data: '🍐' },
          { title: 'peach', data: '🍑' },
          { title: 'cherries', data: '🍒' },
          { title: 'strawberry', data: '🍓' },
          { title: 'kiwi fruit', data: '🥝' },
          { title: 'tomato', data: '🍅' },
          { title: 'coconut', data: '🥥' },
          { title: 'avocado', data: '🥑' },
          { title: 'eggplant', data: '🍆' },
          { title: 'potato', data: '🥔' },
          { title: 'carrot', data: '🥕' },
          { title: 'ear of corn', data: '🌽' },
          { title: 'hot pepper', data: '🌶️' },
          { title: 'cucumber', data: '🥒' },
          { title: 'leafy green', data: '🥬' },
          { title: 'broccoli', data: '🥦' },
          { title: 'garlic', data: '🧄' },
          { title: 'onion', data: '🧅' },
          { title: 'mushroom', data: '🍄' },
          { title: 'peanuts', data: '🥜' },
          { title: 'chestnut', data: '🌰' },
          { title: 'bread', data: '🍞' },
          { title: 'croissant', data: '🥐' },
          { title: 'baguette bread', data: '🥖' },
          { title: 'pretzel', data: '🥨' },
          { title: 'bagel', data: '🥯' },
          { title: 'pancakes', data: '🥞' },
          { title: 'waffle', data: '🧇' },
          { title: 'cheese wedge', data: '🧀' },
          { title: 'meat on bone', data: '🍖' },
          { title: 'poultry leg', data: '🍗' },
          { title: 'cut of meat', data: '🥩' },
          { title: 'bacon', data: '🥓' },
          { title: 'hamburger', data: '🍔' },
          { title: 'french fries', data: '🍟' },
          { title: 'pizza', data: '🍕' },
          { title: 'hot dog', data: '🌭' },
          { title: 'sandwich', data: '🥪' },
          { title: 'taco', data: '🌮' },
          { title: 'burrito', data: '🌯' },
          { title: 'stuffed flatbread', data: '🥙' },
          { title: 'falafel', data: '🧆' },
          { title: 'egg', data: '🥚' },
          { title: 'cooking', data: '🍳' },
          { title: 'shallow pan of food', data: '🥘' },
          { title: 'pot of food', data: '🍲' },
          { title: 'bowl with spoon', data: '🥣' },
          { title: 'green salad', data: '🥗' },
          { title: 'popcorn', data: '🍿' },
          { title: 'butter', data: '🧈' },
          { title: 'salt', data: '🧂' },
          { title: 'canned food', data: '🥫' },
          { title: 'bento box', data: '🍱' },
          { title: 'rice cracker', data: '🍘' },
          { title: 'rice ball', data: '🍙' },
          { title: 'cooked rice', data: '🍚' },
          { title: 'curry rice', data: '🍛' },
          { title: 'steaming bowl', data: '🍜' },
          { title: 'spaghetti', data: '🍝' },
          { title: 'roasted sweet potato', data: '🍠' },
          { title: 'oden', data: '🍢' },
          { title: 'sushi', data: '🍣' },
          { title: 'fried shrimp', data: '🍤' },
          { title: 'fish cake with swirl', data: '🍥' },
          { title: 'moon cake', data: '🥮' },
          { title: 'dango', data: '🍡' },
          { title: 'dumpling', data: '🥟' },
          { title: 'fortune cookie', data: '🥠' },
          { title: 'takeout box', data: '🥡' },
          { title: 'crab', data: '🦀' },
          { title: 'lobster', data: '🦞' },
          { title: 'shrimp', data: '🦐' },
          { title: 'squid', data: '🦑' },
          { title: 'oyster', data: '🦪' },
          { title: 'soft ice cream', data: '🍦' },
          { title: 'shaved ice', data: '🍧' },
          { title: 'ice cream', data: '🍨' },
          { title: 'doughnut', data: '🍩' },
          { title: 'cookie', data: '🍪' },
          { title: 'birthday cake', data: '🎂' },
          { title: 'shortcake', data: '🍰' },
          { title: 'cupcake', data: '🧁' },
          { title: 'pie', data: '🥧' },
          { title: 'chocolate bar', data: '🍫' },
          { title: 'candy', data: '🍬' },
          { title: 'lollipop', data: '🍭' },
          { title: 'custard', data: '🍮' },
          { title: 'honey pot', data: '🍯' },
          { title: 'baby bottle', data: '🍼' },
          { title: 'glass of milk', data: '🥛' },
          { title: 'hot beverage', data: '☕' },
          { title: 'teacup without handle', data: '🍵' },
          { title: 'sake', data: '🍶' },
          { title: 'bottle with popping cork', data: '🍾' },
          { title: 'wine glass', data: '🍷' },
          { title: 'cocktail glass', data: '🍸' },
          { title: 'tropical drink', data: '🍹' },
          { title: 'beer mug', data: '🍺' },
          { title: 'clinking beer mugs', data: '🍻' },
          { title: 'clinking glasses', data: '🥂' },
          { title: 'tumbler glass', data: '🥃' },
          { title: 'cup with straw', data: '🥤' },
          { title: 'beverage box', data: '🧃' },
          { title: 'mate', data: '🧉' },
          { title: 'ice', data: '🧊' },
          { title: 'chopsticks', data: '🥢' },
          { title: 'fork and knife with plate', data: '🍽️' },
          { title: 'fork and knife', data: '🍴' },
          { title: 'spoon', data: '🥄' },
          { title: 'kitchen knife', data: '🔪' },
          { title: 'amphora', data: '🏺' },
        ],
        [
          { title: 'jack-o-lantern', data: '🎃' },
          { title: 'Christmas tree', data: '🎄' },
          { title: 'fireworks', data: '🎆' },
          { title: 'sparkler', data: '🎇' },
          { title: 'firecracker', data: '🧨' },
          { title: 'sparkles', data: '✨' },
          { title: 'balloon', data: '🎈' },
          { title: 'party popper', data: '🎉' },
          { title: 'confetti ball', data: '🎊' },
          { title: 'tanabata tree', data: '🎋' },
          { title: 'pine decoration', data: '🎍' },
          { title: 'Japanese dolls', data: '🎎' },
          { title: 'carp streamer', data: '🎏' },
          { title: 'wind chime', data: '🎐' },
          { title: 'moon viewing ceremony', data: '🎑' },
          { title: 'red envelope', data: '🧧' },
          { title: 'ribbon', data: '🎀' },
          { title: 'wrapped gift', data: '🎁' },
          { title: 'reminder ribbon', data: '🎗️' },
          { title: 'admission tickets', data: '🎟️' },
          { title: 'ticket', data: '🎫' },
          { title: 'military medal', data: '🎖️' },
          { title: 'trophy', data: '🏆' },
          { title: 'sports medal', data: '🏅' },
          { title: '1st place medal', data: '🥇' },
          { title: '2nd place medal', data: '🥈' },
          { title: '3rd place medal', data: '🥉' },
          { title: 'soccer ball', data: '⚽' },
          { title: 'baseball', data: '⚾' },
          { title: 'softball', data: '🥎' },
          { title: 'basketball', data: '🏀' },
          { title: 'volleyball', data: '🏐' },
          { title: 'american football', data: '🏈' },
          { title: 'rugby football', data: '🏉' },
          { title: 'tennis', data: '🎾' },
          { title: 'flying disc', data: '🥏' },
          { title: 'bowling', data: '🎳' },
          { title: 'cricket game', data: '🏏' },
          { title: 'field hockey', data: '🏑' },
          { title: 'ice hockey', data: '🏒' },
          { title: 'lacrosse', data: '🥍' },
          { title: 'ping pong', data: '🏓' },
          { title: 'badminton', data: '🏸' },
          { title: 'boxing glove', data: '🥊' },
          { title: 'martial arts uniform', data: '🥋' },
          { title: 'goal net', data: '🥅' },
          { title: 'flag in hole', data: '⛳' },
          { title: 'ice skate', data: '⛸️' },
          { title: 'fishing pole', data: '🎣' },
          { title: 'diving mask', data: '🤿' },
          { title: 'running shirt', data: '🎽' },
          { title: 'skis', data: '🎿' },
          { title: 'sled', data: '🛷' },
          { title: 'curling stone', data: '🥌' },
          { title: 'direct hit', data: '🎯' },
          { title: 'yo-yo', data: '🪀' },
          { title: 'kite', data: '🪁' },
          { title: 'pool 8 ball', data: '🎱' },
          { title: 'crystal ball', data: '🔮' },
          { title: 'nazar amulet', data: '🧿' },
          { title: 'video game', data: '🎮' },
          { title: 'joystick', data: '🕹️' },
          { title: 'slot machine', data: '🎰' },
          { title: 'game die', data: '🎲' },
          { title: 'puzzle piece', data: '🧩' },
          { title: 'teddy bear', data: '🧸' },
          { title: 'spade suit', data: '♠️' },
          { title: 'heart suit', data: '♥️' },
          { title: 'diamond suit', data: '♦️' },
          { title: 'club suit', data: '♣️' },
          { title: 'chess pawn', data: '♟️' },
          { title: 'joker', data: '🃏' },
          { title: 'mahjong red dragon', data: '🀄' },
          { title: 'flower playing cards', data: '🎴' },
          { title: 'performing arts', data: '🎭' },
          { title: 'framed picture', data: '🖼️' },
          { title: 'artist palette', data: '🎨' },
          { title: 'thread', data: '🧵' },
          { title: 'yarn', data: '🧶' },
        ],
        [
          { title: 'globe showing Europe-Africa', data: '🌍' },
          { title: 'globe showing Americas', data: '🌎' },
          { title: 'globe showing Asia-Australia', data: '🌏' },
          { title: 'globe with meridians', data: '🌐' },
          { title: 'world map', data: '🗺️' },
          { title: 'map of Japan', data: '🗾' },
          { title: 'compass', data: '🧭' },
          { title: 'snow-capped mountain', data: '🏔️' },
          { title: 'mountain', data: '⛰️' },
          { title: 'volcano', data: '🌋' },
          { title: 'mount fuji', data: '🗻' },
          { title: 'camping', data: '🏕️' },
          { title: 'beach with umbrella', data: '🏖️' },
          { title: 'desert', data: '🏜️' },
          { title: 'desert island', data: '🏝️' },
          { title: 'national park', data: '🏞️' },
          { title: 'stadium', data: '🏟️' },
          { title: 'classical building', data: '🏛️' },
          { title: 'building construction', data: '🏗️' },
          { title: 'brick', data: '🧱' },
          { title: 'houses', data: '🏘️' },
          { title: 'derelict house', data: '🏚️' },
          { title: 'house', data: '🏠' },
          { title: 'house with garden', data: '🏡' },
          { title: 'office building', data: '🏢' },
          { title: 'Japanese post office', data: '🏣' },
          { title: 'post office', data: '🏤' },
          { title: 'hospital', data: '🏥' },
          { title: 'bank', data: '🏦' },
          { title: 'hotel', data: '🏨' },
          { title: 'love hotel', data: '🏩' },
          { title: 'convenience store', data: '🏪' },
          { title: 'school', data: '🏫' },
          { title: 'department store', data: '🏬' },
          { title: 'factory', data: '🏭' },
          { title: 'Japanese castle', data: '🏯' },
          { title: 'castle', data: '🏰' },
          { title: 'wedding', data: '💒' },
          { title: 'Tokyo tower', data: '🗼' },
          { title: 'Statue of Liberty', data: '🗽' },
          { title: 'church', data: '⛪' },
          { title: 'mosque', data: '🕌' },
          { title: 'hindu temple', data: '🛕' },
          { title: 'synagogue', data: '🕍' },
          { title: 'shinto shrine', data: '⛩️' },
          { title: 'kaaba', data: '🕋' },
          { title: 'fountain', data: '⛲' },
          { title: 'tent', data: '⛺' },
          { title: 'foggy', data: '🌁' },
          { title: 'night with stars', data: '🌃' },
          { title: 'cityscape', data: '🏙️' },
          { title: 'sunrise over mountains', data: '🌄' },
          { title: 'sunrise', data: '🌅' },
          { title: 'cityscape at dusk', data: '🌆' },
          { title: 'sunset', data: '🌇' },
          { title: 'bridge at night', data: '🌉' },
          { title: 'hot springs', data: '♨️' },
          { title: 'carousel horse', data: '🎠' },
          { title: 'ferris wheel', data: '🎡' },
          { title: 'roller coaster', data: '🎢' },
          { title: 'barber pole', data: '💈' },
          { title: 'circus tent', data: '🎪' },
          { title: 'locomotive', data: '🚂' },
          { title: 'railway car', data: '🚃' },
          { title: 'high-speed train', data: '🚄' },
          { title: 'bullet train', data: '🚅' },
          { title: 'train', data: '🚆' },
          { title: 'metro', data: '🚇' },
          { title: 'light rail', data: '🚈' },
          { title: 'station', data: '🚉' },
          { title: 'tram', data: '🚊' },
          { title: 'monorail', data: '🚝' },
          { title: 'mountain railway', data: '🚞' },
          { title: 'tram car', data: '🚋' },
          { title: 'bus', data: '🚌' },
          { title: 'oncoming bus', data: '🚍' },
          { title: 'trolleybus', data: '🚎' },
          { title: 'minibus', data: '🚐' },
          { title: 'ambulance', data: '🚑' },
          { title: 'fire engine', data: '🚒' },
          { title: 'police car', data: '🚓' },
          { title: 'oncoming police car', data: '🚔' },
          { title: 'taxi', data: '🚕' },
          { title: 'oncoming taxi', data: '🚖' },
          { title: 'automobile', data: '🚗' },
          { title: 'oncoming automobile', data: '🚘' },
          { title: 'sport utility vehicle', data: '🚙' },
          { title: 'delivery truck', data: '🚚' },
          { title: 'articulated lorry', data: '🚛' },
          { title: 'tractor', data: '🚜' },
          { title: 'racing car', data: '🏎️' },
          { title: 'motorcycle', data: '🏍️' },
          { title: 'motor scooter', data: '🛵' },
          { title: 'manual wheelchair', data: '🦽' },
          { title: 'motorized wheelchair', data: '🦼' },
          { title: 'auto rickshaw', data: '🛺' },
          { title: 'bicycle', data: '🚲' },
          { title: 'kick scooter', data: '🛴' },
          { title: 'skateboard', data: '🛹' },
          { title: 'bus stop', data: '🚏' },
          { title: 'motorway', data: '🛣️' },
          { title: 'railway track', data: '🛤️' },
          { title: 'oil drum', data: '🛢️' },
          { title: 'fuel pump', data: '⛽' },
          { title: 'police car light', data: '🚨' },
          { title: 'horizontal traffic light', data: '🚥' },
          { title: 'vertical traffic light', data: '🚦' },
          { title: 'stop sign', data: '🛑' },
          { title: 'construction', data: '🚧' },
          { title: 'anchor', data: '⚓' },
          { title: 'sailboat', data: '⛵' },
          { title: 'canoe', data: '🛶' },
          { title: 'speedboat', data: '🚤' },
          { title: 'passenger ship', data: '🛳️' },
          { title: 'ferry', data: '⛴️' },
          { title: 'motor boat', data: '🛥️' },
          { title: 'ship', data: '🚢' },
          { title: 'airplane', data: '✈️' },
          { title: 'small airplane', data: '🛩️' },
          { title: 'airplane departure', data: '🛫' },
          { title: 'airplane arrival', data: '🛬' },
          { title: 'parachute', data: '🪂' },
          { title: 'seat', data: '💺' },
          { title: 'helicopter', data: '🚁' },
          { title: 'suspension railway', data: '🚟' },
          { title: 'mountain cableway', data: '🚠' },
          { title: 'aerial tramway', data: '🚡' },
          { title: 'satellite', data: '🛰️' },
          { title: 'rocket', data: '🚀' },
          { title: 'flying saucer', data: '🛸' },
          { title: 'bellhop bell', data: '🛎️' },
          { title: 'luggage', data: '🧳' },
          { title: 'hourglass done', data: '⌛' },
          { title: 'hourglass not done', data: '⏳' },
          { title: 'watch', data: '⌚' },
          { title: 'alarm clock', data: '⏰' },
          { title: 'stopwatch', data: '⏱️' },
          { title: 'timer clock', data: '⏲️' },
          { title: 'mantelpiece clock', data: '🕰️' },
          { title: 'twelve o’clock', data: '🕛' },
          { title: 'twelve-thirty', data: '🕧' },
          { title: 'one o’clock', data: '🕐' },
          { title: 'one-thirty', data: '🕜' },
          { title: 'two o’clock', data: '🕑' },
          { title: 'two-thirty', data: '🕝' },
          { title: 'three o’clock', data: '🕒' },
          { title: 'three-thirty', data: '🕞' },
          { title: 'four o’clock', data: '🕓' },
          { title: 'four-thirty', data: '🕟' },
          { title: 'five o’clock', data: '🕔' },
          { title: 'five-thirty', data: '🕠' },
          { title: 'six o’clock', data: '🕕' },
          { title: 'six-thirty', data: '🕡' },
          { title: 'seven o’clock', data: '🕖' },
          { title: 'seven-thirty', data: '🕢' },
          { title: 'eight o’clock', data: '🕗' },
          { title: 'eight-thirty', data: '🕣' },
          { title: 'nine o’clock', data: '🕘' },
          { title: 'nine-thirty', data: '🕤' },
          { title: 'ten o’clock', data: '🕙' },
          { title: 'ten-thirty', data: '🕥' },
          { title: 'eleven o’clock', data: '🕚' },
          { title: 'eleven-thirty', data: '🕦' },
          { title: 'new moon', data: '🌑' },
          { title: 'waxing crescent moon', data: '🌒' },
          { title: 'first quarter moon', data: '🌓' },
          { title: 'waxing gibbous moon', data: '🌔' },
          { title: 'full moon', data: '🌕' },
          { title: 'waning gibbous moon', data: '🌖' },
          { title: 'last quarter moon', data: '🌗' },
          { title: 'waning crescent moon', data: '🌘' },
          { title: 'crescent moon', data: '🌙' },
          { title: 'new moon face', data: '🌚' },
          { title: 'first quarter moon face', data: '🌛' },
          { title: 'last quarter moon face', data: '🌜' },
          { title: 'thermometer', data: '🌡️' },
          { title: 'sun', data: '☀️' },
          { title: 'full moon face', data: '🌝' },
          { title: 'sun with face', data: '🌞' },
          { title: 'ringed planet', data: '🪐' },
          { title: 'star', data: '⭐' },
          { title: 'glowing star', data: '🌟' },
          { title: 'shooting star', data: '🌠' },
          { title: 'milky way', data: '🌌' },
          { title: 'cloud', data: '☁️' },
          { title: 'sun behind cloud', data: '⛅' },
          { title: 'cloud with lightning and rain', data: '⛈️' },
          { title: 'sun behind small cloud', data: '🌤️' },
          { title: 'sun behind large cloud', data: '🌥️' },
          { title: 'sun behind rain cloud', data: '🌦️' },
          { title: 'cloud with rain', data: '🌧️' },
          { title: 'cloud with snow', data: '🌨️' },
          { title: 'cloud with lightning', data: '🌩️' },
          { title: 'tornado', data: '🌪️' },
          { title: 'fog', data: '🌫️' },
          { title: 'wind face', data: '🌬️' },
          { title: 'cyclone', data: '🌀' },
          { title: 'rainbow', data: '🌈' },
          { title: 'closed umbrella', data: '🌂' },
          { title: 'umbrella', data: '☂️' },
          { title: 'umbrella with rain drops', data: '☔' },
          { title: 'umbrella on ground', data: '⛱️' },
          { title: 'high voltage', data: '⚡' },
          { title: 'snowflake', data: '❄️' },
          { title: 'snowman', data: '☃️' },
          { title: 'snowman without snow', data: '⛄' },
          { title: 'comet', data: '☄️' },
          { title: 'fire', data: '🔥' },
          { title: 'droplet', data: '💧' },
          { title: 'water wave', data: '🌊' },
        ],
        [
          { title: 'glasses', data: '👓' },
          { title: 'sunglasses', data: '🕶️' },
          { title: 'goggles', data: '🥽' },
          { title: 'lab coat', data: '🥼' },
          { title: 'safety vest', data: '🦺' },
          { title: 'necktie', data: '👔' },
          { title: 't-shirt', data: '👕' },
          { title: 'jeans', data: '👖' },
          { title: 'scarf', data: '🧣' },
          { title: 'gloves', data: '🧤' },
          { title: 'coat', data: '🧥' },
          { title: 'socks', data: '🧦' },
          { title: 'dress', data: '👗' },
          { title: 'kimono', data: '👘' },
          { title: 'sari', data: '🥻' },
          { title: 'one-piece swimsuit', data: '🩱' },
          { title: 'briefs', data: '🩲' },
          { title: 'shorts', data: '🩳' },
          { title: 'bikini', data: '👙' },
          { title: 'woman’s clothes', data: '👚' },
          { title: 'purse', data: '👛' },
          { title: 'handbag', data: '👜' },
          { title: 'clutch bag', data: '👝' },
          { title: 'shopping bags', data: '🛍️' },
          { title: 'backpack', data: '🎒' },
          { title: 'man’s shoe', data: '👞' },
          { title: 'running shoe', data: '👟' },
          { title: 'hiking boot', data: '🥾' },
          { title: 'flat shoe', data: '🥿' },
          { title: 'high-heeled shoe', data: '👠' },
          { title: 'woman’s sandal', data: '👡' },
          { title: 'ballet shoes', data: '🩰' },
          { title: 'woman’s boot', data: '👢' },
          { title: 'crown', data: '👑' },
          { title: 'woman’s hat', data: '👒' },
          { title: 'top hat', data: '🎩' },
          { title: 'graduation cap', data: '🎓' },
          { title: 'billed cap', data: '🧢' },
          { title: 'rescue worker’s helmet', data: '⛑️' },
          { title: 'prayer beads', data: '📿' },
          { title: 'lipstick', data: '💄' },
          { title: 'ring', data: '💍' },
          { title: 'gem stone', data: '💎' },
          { title: 'muted speaker', data: '🔇' },
          { title: 'speaker low volume', data: '🔈' },
          { title: 'speaker medium volume', data: '🔉' },
          { title: 'speaker high volume', data: '🔊' },
          { title: 'loudspeaker', data: '📢' },
          { title: 'megaphone', data: '📣' },
          { title: 'postal horn', data: '📯' },
          { title: 'bell', data: '🔔' },
          { title: 'bell with slash', data: '🔕' },
          { title: 'musical score', data: '🎼' },
          { title: 'musical note', data: '🎵' },
          { title: 'musical notes', data: '🎶' },
          { title: 'studio microphone', data: '🎙️' },
          { title: 'level slider', data: '🎚️' },
          { title: 'control knobs', data: '🎛️' },
          { title: 'microphone', data: '🎤' },
          { title: 'headphone', data: '🎧' },
          { title: 'radio', data: '📻' },
          { title: 'saxophone', data: '🎷' },
          { title: 'guitar', data: '🎸' },
          { title: 'musical keyboard', data: '🎹' },
          { title: 'trumpet', data: '🎺' },
          { title: 'violin', data: '🎻' },
          { title: 'banjo', data: '🪕' },
          { title: 'drum', data: '🥁' },
          { title: 'mobile phone', data: '📱' },
          { title: 'mobile phone with arrow', data: '📲' },
          { title: 'telephone', data: '☎️' },
          { title: 'telephone receiver', data: '📞' },
          { title: 'pager', data: '📟' },
          { title: 'fax machine', data: '📠' },
          { title: 'battery', data: '🔋' },
          { title: 'electric plug', data: '🔌' },
          { title: 'laptop', data: '💻' },
          { title: 'desktop computer', data: '🖥️' },
          { title: 'printer', data: '🖨️' },
          { title: 'keyboard', data: '⌨️' },
          { title: 'computer mouse', data: '🖱️' },
          { title: 'trackball', data: '🖲️' },
          { title: 'computer disk', data: '💽' },
          { title: 'floppy disk', data: '💾' },
          { title: 'optical disk', data: '💿' },
          { title: 'dvd', data: '📀' },
          { title: 'abacus', data: '🧮' },
          { title: 'movie camera', data: '🎥' },
          { title: 'film frames', data: '🎞️' },
          { title: 'film projector', data: '📽️' },
          { title: 'clapper board', data: '🎬' },
          { title: 'television', data: '📺' },
          { title: 'camera', data: '📷' },
          { title: 'camera with flash', data: '📸' },
          { title: 'video camera', data: '📹' },
          { title: 'videocassette', data: '📼' },
          { title: 'magnifying glass tilted left', data: '🔍' },
          { title: 'magnifying glass tilted right', data: '🔎' },
          { title: 'candle', data: '🕯️' },
          { title: 'light bulb', data: '💡' },
          { title: 'flashlight', data: '🔦' },
          { title: 'red paper lantern', data: '🏮' },
          { title: 'diya lamp', data: '🪔' },
          { title: 'notebook with decorative cover', data: '📔' },
          { title: 'closed book', data: '📕' },
          { title: 'open book', data: '📖' },
          { title: 'green book', data: '📗' },
          { title: 'blue book', data: '📘' },
          { title: 'orange book', data: '📙' },
          { title: 'books', data: '📚' },
          { title: 'notebook', data: '📓' },
          { title: 'ledger', data: '📒' },
          { title: 'page with curl', data: '📃' },
          { title: 'scroll', data: '📜' },
          { title: 'page facing up', data: '📄' },
          { title: 'newspaper', data: '📰' },
          { title: 'rolled-up newspaper', data: '🗞️' },
          { title: 'bookmark tabs', data: '📑' },
          { title: 'bookmark', data: '🔖' },
          { title: 'label', data: '🏷️' },
          { title: 'money bag', data: '💰' },
          { title: 'yen banknote', data: '💴' },
          { title: 'dollar banknote', data: '💵' },
          { title: 'euro banknote', data: '💶' },
          { title: 'pound banknote', data: '💷' },
          { title: 'money with wings', data: '💸' },
          { title: 'credit card', data: '💳' },
          { title: 'receipt', data: '🧾' },
          { title: 'chart increasing with yen', data: '💹' },
          { title: 'envelope', data: '✉️' },
          { title: 'e-mail', data: '📧' },
          { title: 'incoming envelope', data: '📨' },
          { title: 'envelope with arrow', data: '📩' },
          { title: 'outbox tray', data: '📤' },
          { title: 'inbox tray', data: '📥' },
          { title: 'package', data: '📦' },
          { title: 'closed mailbox with raised flag', data: '📫' },
          { title: 'closed mailbox with lowered flag', data: '📪' },
          { title: 'open mailbox with raised flag', data: '📬' },
          { title: 'open mailbox with lowered flag', data: '📭' },
          { title: 'postbox', data: '📮' },
          { title: 'ballot box with ballot', data: '🗳️' },
          { title: 'pencil', data: '✏️' },
          { title: 'black nib', data: '✒️' },
          { title: 'fountain pen', data: '🖋️' },
          { title: 'pen', data: '🖊️' },
          { title: 'paintbrush', data: '🖌️' },
          { title: 'crayon', data: '🖍️' },
          { title: 'memo', data: '📝' },
          { title: 'briefcase', data: '💼' },
          { title: 'file folder', data: '📁' },
          { title: 'open file folder', data: '📂' },
          { title: 'card index dividers', data: '🗂️' },
          { title: 'calendar', data: '📅' },
          { title: 'tear-off calendar', data: '📆' },
          { title: 'spiral notepad', data: '🗒️' },
          { title: 'spiral calendar', data: '🗓️' },
          { title: 'card index', data: '📇' },
          { title: 'chart increasing', data: '📈' },
          { title: 'chart decreasing', data: '📉' },
          { title: 'bar chart', data: '📊' },
          { title: 'clipboard', data: '📋' },
          { title: 'pushpin', data: '📌' },
          { title: 'round pushpin', data: '📍' },
          { title: 'paperclip', data: '📎' },
          { title: 'linked paperclips', data: '🖇️' },
          { title: 'straight ruler', data: '📏' },
          { title: 'triangular ruler', data: '📐' },
          { title: 'scissors', data: '✂️' },
          { title: 'card file box', data: '🗃️' },
          { title: 'file cabinet', data: '🗄️' },
          { title: 'wastebasket', data: '🗑️' },
          { title: 'locked', data: '🔒' },
          { title: 'unlocked', data: '🔓' },
          { title: 'locked with pen', data: '🔏' },
          { title: 'locked with key', data: '🔐' },
          { title: 'key', data: '🔑' },
          { title: 'old key', data: '🗝️' },
          { title: 'hammer', data: '🔨' },
          { title: 'axe', data: '🪓' },
          { title: 'pick', data: '⛏️' },
          { title: 'hammer and pick', data: '⚒️' },
          { title: 'hammer and wrench', data: '🛠️' },
          { title: 'dagger', data: '🗡️' },
          { title: 'crossed swords', data: '⚔️' },
          { title: 'pistol', data: '🔫' },
          { title: 'bow and arrow', data: '🏹' },
          { title: 'shield', data: '🛡️' },
          { title: 'wrench', data: '🔧' },
          { title: 'nut and bolt', data: '🔩' },
          { title: 'gear', data: '⚙️' },
          { title: 'clamp', data: '🗜️' },
          { title: 'balance scale', data: '⚖️' },
          { title: 'white cane', data: '🦯' },
          { title: 'link', data: '🔗' },
          { title: 'chains', data: '⛓️' },
          { title: 'toolbox', data: '🧰' },
          { title: 'magnet', data: '🧲' },
          { title: 'alembic', data: '⚗️' },
          { title: 'test tube', data: '🧪' },
          { title: 'petri dish', data: '🧫' },
          { title: 'dna', data: '🧬' },
          { title: 'microscope', data: '🔬' },
          { title: 'telescope', data: '🔭' },
          { title: 'satellite antenna', data: '📡' },
          { title: 'syringe', data: '💉' },
          { title: 'drop of blood', data: '🩸' },
          { title: 'pill', data: '💊' },
          { title: 'adhesive bandage', data: '🩹' },
          { title: 'stethoscope', data: '🩺' },
          { title: 'door', data: '🚪' },
          { title: 'bed', data: '🛏️' },
          { title: 'couch and lamp', data: '🛋️' },
          { title: 'chair', data: '🪑' },
          { title: 'toilet', data: '🚽' },
          { title: 'shower', data: '🚿' },
          { title: 'bathtub', data: '🛁' },
          { title: 'razor', data: '🪒' },
          { title: 'lotion bottle', data: '🧴' },
          { title: 'safety pin', data: '🧷' },
          { title: 'broom', data: '🧹' },
          { title: 'basket', data: '🧺' },
          { title: 'roll of paper', data: '🧻' },
          { title: 'soap', data: '🧼' },
          { title: 'sponge', data: '🧽' },
          { title: 'fire extinguisher', data: '🧯' },
          { title: 'shopping cart', data: '🛒' },
          { title: 'cigarette', data: '🚬' },
          { title: 'coffin', data: '⚰️' },
          { title: 'funeral urn', data: '⚱️' },
          { title: 'moai', data: '🗿' },
        ],
        [
          { title: 'ATM sign', data: '🏧' },
          { title: 'litter in bin sign', data: '🚮' },
          { title: 'potable water', data: '🚰' },
          { title: 'wheelchair symbol', data: '♿' },
          { title: 'men’s room', data: '🚹' },
          { title: 'women’s room', data: '🚺' },
          { title: 'restroom', data: '🚻' },
          { title: 'baby symbol', data: '🚼' },
          { title: 'water closet', data: '🚾' },
          { title: 'passport control', data: '🛂' },
          { title: 'customs', data: '🛃' },
          { title: 'baggage claim', data: '🛄' },
          { title: 'left luggage', data: '🛅' },
          { title: 'warning', data: '⚠️' },
          { title: 'children crossing', data: '🚸' },
          { title: 'no entry', data: '⛔' },
          { title: 'prohibited', data: '🚫' },
          { title: 'no bicycles', data: '🚳' },
          { title: 'no smoking', data: '🚭' },
          { title: 'no littering', data: '🚯' },
          { title: 'non-potable water', data: '🚱' },
          { title: 'no pedestrians', data: '🚷' },
          { title: 'no mobile phones', data: '📵' },
          { title: 'no one under eighteen', data: '🔞' },
          { title: 'radioactive', data: '☢️' },
          { title: 'biohazard', data: '☣️' },
          { title: 'up arrow', data: '⬆️' },
          { title: 'up-right arrow', data: '↗️' },
          { title: 'right arrow', data: '➡️' },
          { title: 'down-right arrow', data: '↘️' },
          { title: 'down arrow', data: '⬇️' },
          { title: 'down-left arrow', data: '↙️' },
          { title: 'left arrow', data: '⬅️' },
          { title: 'up-left arrow', data: '↖️' },
          { title: 'up-down arrow', data: '↕️' },
          { title: 'left-right arrow', data: '↔️' },
          { title: 'right arrow curving left', data: '↩️' },
          { title: 'left arrow curving right', data: '↪️' },
          { title: 'right arrow curving up', data: '⤴️' },
          { title: 'right arrow curving down', data: '⤵️' },
          { title: 'clockwise vertical arrows', data: '🔃' },
          { title: 'counterclockwise arrows button', data: '🔄' },
          { title: 'BACK arrow', data: '🔙' },
          { title: 'END arrow', data: '🔚' },
          { title: 'ON! arrow', data: '🔛' },
          { title: 'SOON arrow', data: '🔜' },
          { title: 'TOP arrow', data: '🔝' },
          { title: 'place of worship', data: '🛐' },
          { title: 'atom symbol', data: '⚛️' },
          { title: 'om', data: '🕉️' },
          { title: 'star of David', data: '✡️' },
          { title: 'wheel of dharma', data: '☸️' },
          { title: 'yin yang', data: '☯️' },
          { title: 'latin cross', data: '✝️' },
          { title: 'orthodox cross', data: '☦️' },
          { title: 'star and crescent', data: '☪️' },
          { title: 'peace symbol', data: '☮️' },
          { title: 'menorah', data: '🕎' },
          { title: 'dotted six-pointed star', data: '🔯' },
          { title: 'Aries', data: '♈' },
          { title: 'Taurus', data: '♉' },
          { title: 'Gemini', data: '♊' },
          { title: 'Cancer', data: '♋' },
          { title: 'Leo', data: '♌' },
          { title: 'Virgo', data: '♍' },
          { title: 'Libra', data: '♎' },
          { title: 'Scorpio', data: '♏' },
          { title: 'Sagittarius', data: '♐' },
          { title: 'Capricorn', data: '♑' },
          { title: 'Aquarius', data: '♒' },
          { title: 'Pisces', data: '♓' },
          { title: 'Ophiuchus', data: '⛎' },
          { title: 'shuffle tracks button', data: '🔀' },
          { title: 'repeat button', data: '🔁' },
          { title: 'repeat single button', data: '🔂' },
          { title: 'play button', data: '▶️' },
          { title: 'fast-forward button', data: '⏩' },
          { title: 'next track button', data: '⏭️' },
          { title: 'play or pause button', data: '⏯️' },
          { title: 'reverse button', data: '◀️' },
          { title: 'fast reverse button', data: '⏪' },
          { title: 'last track button', data: '⏮️' },
          { title: 'upwards button', data: '🔼' },
          { title: 'fast up button', data: '⏫' },
          { title: 'downwards button', data: '🔽' },
          { title: 'fast down button', data: '⏬' },
          { title: 'pause button', data: '⏸️' },
          { title: 'stop button', data: '⏹️' },
          { title: 'record button', data: '⏺️' },
          { title: 'eject button', data: '⏏️' },
          { title: 'cinema', data: '🎦' },
          { title: 'dim button', data: '🔅' },
          { title: 'bright button', data: '🔆' },
          { title: 'antenna bars', data: '📶' },
          { title: 'vibration mode', data: '📳' },
          { title: 'mobile phone off', data: '📴' },
          { title: 'female sign', data: '♀️' },
          { title: 'male sign', data: '♂️' },
          { title: 'multiply', data: '✖️' },
          { title: 'plus', data: '➕' },
          { title: 'minus', data: '➖' },
          { title: 'divide', data: '➗' },
          { title: 'infinity', data: '♾️' },
          { title: 'double exclamation mark', data: '‼️' },
          { title: 'exclamation question mark', data: '⁉️' },
          { title: 'question mark', data: '❓' },
          { title: 'white question mark', data: '❔' },
          { title: 'white exclamation mark', data: '❕' },
          { title: 'exclamation mark', data: '❗' },
          { title: 'wavy dash', data: '〰️' },
          { title: 'currency exchange', data: '💱' },
          { title: 'heavy dollar sign', data: '💲' },
          { title: 'medical symbol', data: '⚕️' },
          { title: 'recycling symbol', data: '♻️' },
          { title: 'fleur-de-lis', data: '⚜️' },
          { title: 'trident emblem', data: '🔱' },
          { title: 'name badge', data: '📛' },
          { title: 'Japanese symbol for beginner', data: '🔰' },
          { title: 'hollow red circle', data: '⭕' },
          { title: 'check mark button', data: '✅' },
          { title: 'check box with check', data: '☑️' },
          { title: 'check mark', data: '✔️' },
          { title: 'cross mark', data: '❌' },
          { title: 'cross mark button', data: '❎' },
          { title: 'curly loop', data: '➰' },
          { title: 'double curly loop', data: '➿' },
          { title: 'part alternation mark', data: '〽️' },
          { title: 'eight-spoked asterisk', data: '✳️' },
          { title: 'eight-pointed star', data: '✴️' },
          { title: 'sparkle', data: '❇️' },
          { title: 'copyright', data: '©️' },
          { title: 'registered', data: '®️' },
          { title: 'trade mark', data: '™️' },
          { title: 'keycap: #', data: '#️⃣' },
          { title: 'keycap: *', data: '*️⃣' },
          { title: 'keycap: 0', data: '0️⃣' },
          { title: 'keycap: 1', data: '1️⃣' },
          { title: 'keycap: 2', data: '2️⃣' },
          { title: 'keycap: 3', data: '3️⃣' },
          { title: 'keycap: 4', data: '4️⃣' },
          { title: 'keycap: 5', data: '5️⃣' },
          { title: 'keycap: 6', data: '6️⃣' },
          { title: 'keycap: 7', data: '7️⃣' },
          { title: 'keycap: 8', data: '8️⃣' },
          { title: 'keycap: 9', data: '9️⃣' },
          { title: 'keycap: 10', data: '🔟' },
          { title: 'input latin uppercase', data: '🔠' },
          { title: 'input latin lowercase', data: '🔡' },
          { title: 'input numbers', data: '🔢' },
          { title: 'input symbols', data: '🔣' },
          { title: 'input latin letters', data: '🔤' },
          { title: 'A button (blood type)', data: '🅰️' },
          { title: 'AB button (blood type)', data: '🆎' },
          { title: 'B button (blood type)', data: '🅱️' },
          { title: 'CL button', data: '🆑' },
          { title: 'COOL button', data: '🆒' },
          { title: 'FREE button', data: '🆓' },
          { title: 'information', data: 'ℹ️' },
          { title: 'ID button', data: '🆔' },
          { title: 'circled M', data: 'Ⓜ️' },
          { title: 'NEW button', data: '🆕' },
          { title: 'NG button', data: '🆖' },
          { title: 'O button (blood type)', data: '🅾️' },
          { title: 'OK button', data: '🆗' },
          { title: 'P button', data: '🅿️' },
          { title: 'SOS button', data: '🆘' },
          { title: 'UP! button', data: '🆙' },
          { title: 'VS button', data: '🆚' },
          { title: 'Japanese “here” button', data: '🈁' },
          { title: 'Japanese “service charge” button', data: '🈂️' },
          { title: 'Japanese “monthly amount” button', data: '🈷️' },
          { title: 'Japanese “not free of charge” button', data: '🈶' },
          { title: 'Japanese “reserved” button', data: '🈯' },
          { title: 'Japanese “bargain” button', data: '🉐' },
          { title: 'Japanese “discount” button', data: '🈹' },
          { title: 'Japanese “free of charge” button', data: '🈚' },
          { title: 'Japanese “prohibited” button', data: '🈲' },
          { title: 'Japanese “acceptable” button', data: '🉑' },
          { title: 'Japanese “application” button', data: '🈸' },
          { title: 'Japanese “passing grade” button', data: '🈴' },
          { title: 'Japanese “vacancy” button', data: '🈳' },
          { title: 'Japanese “congratulations” button', data: '㊗️' },
          { title: 'Japanese “secret” button', data: '㊙️' },
          { title: 'Japanese “open for business” button', data: '🈺' },
          { title: 'Japanese “no vacancy” button', data: '🈵' },
          { title: 'red circle', data: '🔴' },
          { title: 'orange circle', data: '🟠' },
          { title: 'yellow circle', data: '🟡' },
          { title: 'green circle', data: '🟢' },
          { title: 'blue circle', data: '🔵' },
          { title: 'purple circle', data: '🟣' },
          { title: 'brown circle', data: '🟤' },
          { title: 'black circle', data: '⚫' },
          { title: 'white circle', data: '⚪' },
          { title: 'red square', data: '🟥' },
          { title: 'orange square', data: '🟧' },
          { title: 'yellow square', data: '🟨' },
          { title: 'green square', data: '🟩' },
          { title: 'blue square', data: '🟦' },
          { title: 'purple square', data: '🟪' },
          { title: 'brown square', data: '🟫' },
          { title: 'black large square', data: '⬛' },
          { title: 'white large square', data: '⬜' },
          { title: 'black medium square', data: '◼️' },
          { title: 'white medium square', data: '◻️' },
          { title: 'black medium-small square', data: '◾' },
          { title: 'white medium-small square', data: '◽' },
          { title: 'black small square', data: '▪️' },
          { title: 'white small square', data: '▫️' },
          { title: 'large orange diamond', data: '🔶' },
          { title: 'large blue diamond', data: '🔷' },
          { title: 'small orange diamond', data: '🔸' },
          { title: 'small blue diamond', data: '🔹' },
          { title: 'red triangle pointed up', data: '🔺' },
          { title: 'red triangle pointed down', data: '🔻' },
          { title: 'diamond with a dot', data: '💠' },
          { title: 'radio button', data: '🔘' },
          { title: 'white square button', data: '🔳' },
          { title: 'black square button', data: '🔲' },
        ],
        [
          { title: 'chequered flag', data: '🏁' },
          { title: 'triangular flag', data: '🚩' },
          { title: 'crossed flags flag', data: '🎌' },
          { title: 'black flag', data: '🏴' },
          { title: 'white flag', data: '🏳️' },
          { title: 'rainbow flag', data: '🏳️‍🌈' },
          { title: 'pirate flag', data: '🏴‍☠️' },
          { title: 'Ascension Island flag', data: '🇦🇨' },
          { title: 'Andorra flag', data: '🇦🇩' },
          { title: 'United Arab Emirates flag', data: '🇦🇪' },
          { title: 'Afghanistan flag', data: '🇦🇫' },
          { title: 'Antigua &amp; Barbuda flag', data: '🇦🇬' },
          { title: 'Anguilla flag', data: '🇦🇮' },
          { title: 'Albania flag', data: '🇦🇱' },
          { title: 'Armenia flag', data: '🇦🇲' },
          { title: 'Angola flag', data: '🇦🇴' },
          { title: 'Antarctica flag', data: '🇦🇶' },
          { title: 'Argentina flag', data: '🇦🇷' },
          { title: 'American Samoa flag', data: '🇦🇸' },
          { title: 'Austria flag', data: '🇦🇹' },
          { title: 'Australia flag', data: '🇦🇺' },
          { title: 'Aruba flag', data: '🇦🇼' },
          { title: 'Åland Islands flag', data: '🇦🇽' },
          { title: 'Azerbaijan flag', data: '🇦🇿' },
          { title: 'Bosnia &amp; Herzegovina flag', data: '🇧🇦' },
          { title: 'Barbados flag', data: '🇧🇧' },
          { title: 'Bangladesh flag', data: '🇧🇩' },
          { title: 'Belgium flag', data: '🇧🇪' },
          { title: 'Burkina Faso flag', data: '🇧🇫' },
          { title: 'Bulgaria flag', data: '🇧🇬' },
          { title: 'Bahrain flag', data: '🇧🇭' },
          { title: 'Burundi flag', data: '🇧🇮' },
          { title: 'Benin flag', data: '🇧🇯' },
          { title: 'St. Barthélemy flag', data: '🇧🇱' },
          { title: 'Bermuda flag', data: '🇧🇲' },
          { title: 'Brunei flag', data: '🇧🇳' },
          { title: 'Bolivia flag', data: '🇧🇴' },
          { title: 'Caribbean Netherlands flag', data: '🇧🇶' },
          { title: 'Brazil flag', data: '🇧🇷' },
          { title: 'Bahamas flag', data: '🇧🇸' },
          { title: 'Bhutan flag', data: '🇧🇹' },
          { title: 'Bouvet Island flag', data: '🇧🇻' },
          { title: 'Botswana flag', data: '🇧🇼' },
          { title: 'Belarus flag', data: '🇧🇾' },
          { title: 'Belize flag', data: '🇧🇿' },
          { title: 'Canada flag', data: '🇨🇦' },
          { title: 'Cocos (Keeling) Islands flag', data: '🇨🇨' },
          { title: 'Congo - Kinshasa flag', data: '🇨🇩' },
          { title: 'Central African Republic flag', data: '🇨🇫' },
          { title: 'Congo - Brazzaville flag', data: '🇨🇬' },
          { title: 'Switzerland flag', data: '🇨🇭' },
          { title: 'Côte d’Ivoire flag', data: '🇨🇮' },
          { title: 'Cook Islands flag', data: '🇨🇰' },
          { title: 'Chile flag', data: '🇨🇱' },
          { title: 'Cameroon flag', data: '🇨🇲' },
          { title: 'China flag', data: '🇨🇳' },
          { title: 'Colombia flag', data: '🇨🇴' },
          { title: 'Clipperton Island flag', data: '🇨🇵' },
          { title: 'Costa Rica flag', data: '🇨🇷' },
          { title: 'Cuba flag', data: '🇨🇺' },
          { title: 'Cape Verde flag', data: '🇨🇻' },
          { title: 'Curaçao flag', data: '🇨🇼' },
          { title: 'Christmas Island flag', data: '🇨🇽' },
          { title: 'Cyprus flag', data: '🇨🇾' },
          { title: 'Czechia flag', data: '🇨🇿' },
          { title: 'Germany flag', data: '🇩🇪' },
          { title: 'Diego Garcia flag', data: '🇩🇬' },
          { title: 'Djibouti flag', data: '🇩🇯' },
          { title: 'Denmark flag', data: '🇩🇰' },
          { title: 'Dominica flag', data: '🇩🇲' },
          { title: 'Dominican Republic flag', data: '🇩🇴' },
          { title: 'Algeria flag', data: '🇩🇿' },
          { title: 'Ceuta &amp; Melilla flag', data: '🇪🇦' },
          { title: 'Ecuador flag', data: '🇪🇨' },
          { title: 'Estonia flag', data: '🇪🇪' },
          { title: 'Egypt flag', data: '🇪🇬' },
          { title: 'Western Sahara flag', data: '🇪🇭' },
          { title: 'Eritrea flag', data: '🇪🇷' },
          { title: 'Spain flag', data: '🇪🇸' },
          { title: 'Ethiopia flag', data: '🇪🇹' },
          { title: 'European Union flag', data: '🇪🇺' },
          { title: 'Finland flag', data: '🇫🇮' },
          { title: 'Fiji flag', data: '🇫🇯' },
          { title: 'Falkland Islands flag', data: '🇫🇰' },
          { title: 'Micronesia flag', data: '🇫🇲' },
          { title: 'Faroe Islands flag', data: '🇫🇴' },
          { title: 'France flag', data: '🇫🇷' },
          { title: 'Gabon flag', data: '🇬🇦' },
          { title: 'United Kingdom flag', data: '🇬🇧' },
          { title: 'Grenada flag', data: '🇬🇩' },
          { title: 'Georgia flag', data: '🇬🇪' },
          { title: 'French Guiana flag', data: '🇬🇫' },
          { title: 'Guernsey flag', data: '🇬🇬' },
          { title: 'Ghana flag', data: '🇬🇭' },
          { title: 'Gibraltar flag', data: '🇬🇮' },
          { title: 'Greenland flag', data: '🇬🇱' },
          { title: 'Gambia flag', data: '🇬🇲' },
          { title: 'Guinea flag', data: '🇬🇳' },
          { title: 'Guadeloupe flag', data: '🇬🇵' },
          { title: 'Equatorial Guinea flag', data: '🇬🇶' },
          { title: 'Greece flag', data: '🇬🇷' },
          {
            title: 'South Georgia &amp; South Sandwich Islands flag',
            data: '🇬🇸',
          },
          { title: 'Guatemala flag', data: '🇬🇹' },
          { title: 'Guam flag', data: '🇬🇺' },
          { title: 'Guinea-Bissau flag', data: '🇬🇼' },
          { title: 'Guyana flag', data: '🇬🇾' },
          { title: 'Hong Kong SAR China flag', data: '🇭🇰' },
          { title: 'Heard &amp; McDonald Islands flag', data: '🇭🇲' },
          { title: 'Honduras flag', data: '🇭🇳' },
          { title: 'Croatia flag', data: '🇭🇷' },
          { title: 'Haiti flag', data: '🇭🇹' },
          { title: 'Hungary flag', data: '🇭🇺' },
          { title: 'Canary Islands flag', data: '🇮🇨' },
          { title: 'Indonesia flag', data: '🇮🇩' },
          { title: 'Ireland flag', data: '🇮🇪' },
          { title: 'Israel flag', data: '🇮🇱' },
          { title: 'Isle of Man flag', data: '🇮🇲' },
          { title: 'India flag', data: '🇮🇳' },
          { title: 'British Indian Ocean Territory flag', data: '🇮🇴' },
          { title: 'Iraq flag', data: '🇮🇶' },
          { title: 'Iran flag', data: '🇮🇷' },
          { title: 'Iceland flag', data: '🇮🇸' },
          { title: 'Italy flag', data: '🇮🇹' },
          { title: 'Jersey flag', data: '🇯🇪' },
          { title: 'Jamaica flag', data: '🇯🇲' },
          { title: 'Jordan flag', data: '🇯🇴' },
          { title: 'Japan flag', data: '🇯🇵' },
          { title: 'Kenya flag', data: '🇰🇪' },
          { title: 'Kyrgyzstan flag', data: '🇰🇬' },
          { title: 'Cambodia flag', data: '🇰🇭' },
          { title: 'Kiribati flag', data: '🇰🇮' },
          { title: 'Comoros flag', data: '🇰🇲' },
          { title: 'St. Kitts &amp; Nevis flag', data: '🇰🇳' },
          { title: 'North Korea flag', data: '🇰🇵' },
          { title: 'South Korea flag', data: '🇰🇷' },
          { title: 'Kuwait flag', data: '🇰🇼' },
          { title: 'Cayman Islands flag', data: '🇰🇾' },
          { title: 'Kazakhstan flag', data: '🇰🇿' },
          { title: 'Laos flag', data: '🇱🇦' },
          { title: 'Lebanon flag', data: '🇱🇧' },
          { title: 'St. Lucia flag', data: '🇱🇨' },
          { title: 'Liechtenstein flag', data: '🇱🇮' },
          { title: 'Sri Lanka flag', data: '🇱🇰' },
          { title: 'Liberia flag', data: '🇱🇷' },
          { title: 'Lesotho flag', data: '🇱🇸' },
          { title: 'Lithuania flag', data: '🇱🇹' },
          { title: 'Luxembourg flag', data: '🇱🇺' },
          { title: 'Latvia flag', data: '🇱🇻' },
          { title: 'Libya flag', data: '🇱🇾' },
          { title: 'Morocco flag', data: '🇲🇦' },
          { title: 'Monaco flag', data: '🇲🇨' },
          { title: 'Moldova flag', data: '🇲🇩' },
          { title: 'Montenegro flag', data: '🇲🇪' },
          { title: 'St. Martin flag', data: '🇲🇫' },
          { title: 'Madagascar flag', data: '🇲🇬' },
          { title: 'Marshall Islands flag', data: '🇲🇭' },
          { title: 'North Macedonia flag', data: '🇲🇰' },
          { title: 'Mali flag', data: '🇲🇱' },
          { title: 'Myanmar (Burma) flag', data: '🇲🇲' },
          { title: 'Mongolia flag', data: '🇲🇳' },
          { title: 'Macao SAR China flag', data: '🇲🇴' },
          { title: 'Northern Mariana Islands flag', data: '🇲🇵' },
          { title: 'Martinique flag', data: '🇲🇶' },
          { title: 'Mauritania flag', data: '🇲🇷' },
          { title: 'Montserrat flag', data: '🇲🇸' },
          { title: 'Malta flag', data: '🇲🇹' },
          { title: 'Mauritius flag', data: '🇲🇺' },
          { title: 'Maldives flag', data: '🇲🇻' },
          { title: 'Malawi flag', data: '🇲🇼' },
          { title: 'Mexico flag', data: '🇲🇽' },
          { title: 'Malaysia flag', data: '🇲🇾' },
          { title: 'Mozambique flag', data: '🇲🇿' },
          { title: 'Namibia flag', data: '🇳🇦' },
          { title: 'New Caledonia flag', data: '🇳🇨' },
          { title: 'Niger flag', data: '🇳🇪' },
          { title: 'Norfolk Island flag', data: '🇳🇫' },
          { title: 'Nigeria flag', data: '🇳🇬' },
          { title: 'Nicaragua flag', data: '🇳🇮' },
          { title: 'Netherlands flag', data: '🇳🇱' },
          { title: 'Norway flag', data: '🇳🇴' },
          { title: 'Nepal flag', data: '🇳🇵' },
          { title: 'Nauru flag', data: '🇳🇷' },
          { title: 'Niue flag', data: '🇳🇺' },
          { title: 'New Zealand flag', data: '🇳🇿' },
          { title: 'Oman flag', data: '🇴🇲' },
          { title: 'Panama flag', data: '🇵🇦' },
          { title: 'Peru flag', data: '🇵🇪' },
          { title: 'French Polynesia flag', data: '🇵🇫' },
          { title: 'Papua New Guinea flag', data: '🇵🇬' },
          { title: 'Philippines flag', data: '🇵🇭' },
          { title: 'Pakistan flag', data: '🇵🇰' },
          { title: 'Poland flag', data: '🇵🇱' },
          { title: 'St. Pierre &amp; Miquelon flag', data: '🇵🇲' },
          { title: 'Pitcairn Islands flag', data: '🇵🇳' },
          { title: 'Puerto Rico flag', data: '🇵🇷' },
          { title: 'Palestinian Territories flag', data: '🇵🇸' },
          { title: 'Portugal flag', data: '🇵🇹' },
          { title: 'Palau flag', data: '🇵🇼' },
          { title: 'Paraguay flag', data: '🇵🇾' },
          { title: 'Qatar flag', data: '🇶🇦' },
          { title: 'Réunion flag', data: '🇷🇪' },
          { title: 'Romania flag', data: '🇷🇴' },
          { title: 'Serbia flag', data: '🇷🇸' },
          { title: 'Russia flag', data: '🇷🇺' },
          { title: 'Rwanda flag', data: '🇷🇼' },
          { title: 'Saudi Arabia flag', data: '🇸🇦' },
          { title: 'Solomon Islands flag', data: '🇸🇧' },
          { title: 'Seychelles flag', data: '🇸🇨' },
          { title: 'Sudan flag', data: '🇸🇩' },
          { title: 'Sweden flag', data: '🇸🇪' },
          { title: 'Singapore flag', data: '🇸🇬' },
          { title: 'St. Helena flag', data: '🇸🇭' },
          { title: 'Slovenia flag', data: '🇸🇮' },
          { title: 'Svalbard &amp; Jan Mayen flag', data: '🇸🇯' },
          { title: 'Slovakia flag', data: '🇸🇰' },
          { title: 'Sierra Leone flag', data: '🇸🇱' },
          { title: 'San Marino flag', data: '🇸🇲' },
          { title: 'Senegal flag', data: '🇸🇳' },
          { title: 'Somalia flag', data: '🇸🇴' },
          { title: 'Suriname flag', data: '🇸🇷' },
          { title: 'South Sudan flag', data: '🇸🇸' },
          { title: 'São Tomé &amp; Príncipe flag', data: '🇸🇹' },
          { title: 'El Salvador flag', data: '🇸🇻' },
          { title: 'Sint Maarten flag', data: '🇸🇽' },
          { title: 'Syria flag', data: '🇸🇾' },
          { title: 'Eswatini flag', data: '🇸🇿' },
          { title: 'Tristan da Cunha flag', data: '🇹🇦' },
          { title: 'Turks &amp; Caicos Islands flag', data: '🇹🇨' },
          { title: 'Chad flag', data: '🇹🇩' },
          { title: 'French Southern Territories flag', data: '🇹🇫' },
          { title: 'Togo flag', data: '🇹🇬' },
          { title: 'Thailand flag', data: '🇹🇭' },
          { title: 'Tajikistan flag', data: '🇹🇯' },
          { title: 'Tokelau flag', data: '🇹🇰' },
          { title: 'Timor-Leste flag', data: '🇹🇱' },
          { title: 'Turkmenistan flag', data: '🇹🇲' },
          { title: 'Tunisia flag', data: '🇹🇳' },
          { title: 'Tonga flag', data: '🇹🇴' },
          { title: 'Turkey flag', data: '🇹🇷' },
          { title: 'Trinidad &amp; Tobago flag', data: '🇹🇹' },
          { title: 'Tuvalu flag', data: '🇹🇻' },
          { title: 'Taiwan flag', data: '🇹🇼' },
          { title: 'Tanzania flag', data: '🇹🇿' },
          { title: 'Ukraine flag', data: '🇺🇦' },
          { title: 'Uganda flag', data: '🇺🇬' },
          { title: 'U.S. Outlying Islands flag', data: '🇺🇲' },
          { title: 'United Nations flag', data: '🇺🇳' },
          { title: 'United States flag', data: '🇺🇸' },
          { title: 'Uruguay flag', data: '🇺🇾' },
          { title: 'Uzbekistan flag', data: '🇺🇿' },
          { title: 'Vatican City flag', data: '🇻🇦' },
          { title: 'St. Vincent &amp; Grenadines flag', data: '🇻🇨' },
          { title: 'Venezuela flag', data: '🇻🇪' },
          { title: 'British Virgin Islands flag', data: '🇻🇬' },
          { title: 'U.S. Virgin Islands flag', data: '🇻🇮' },
          { title: 'Vietnam flag', data: '🇻🇳' },
          { title: 'Vanuatu flag', data: '🇻🇺' },
          { title: 'Wallis &amp; Futuna flag', data: '🇼🇫' },
          { title: 'Samoa flag', data: '🇼🇸' },
          { title: 'Kosovo flag', data: '🇽🇰' },
          { title: 'Yemen flag', data: '🇾🇪' },
          { title: 'Mayotte flag', data: '🇾🇹' },
          { title: 'South Africa flag', data: '🇿🇦' },
          { title: 'Zambia flag', data: '🇿🇲' },
          { title: 'Zimbabwe flag', data: '🇿🇼' },
          { title: 'England flag', data: '🏴󠁧󠁢󠁥󠁮󠁧󠁿' },
          { title: 'Scotland flag', data: '🏴󠁧󠁢󠁳󠁣󠁴󠁿' },
          { title: 'Wales flag', data: '🏴󠁧󠁢󠁷󠁬󠁳󠁿' },
        ],
      ],
    };
  },
  methods: {
    toogle() {
      if (this.disabled === false) this.visible = !this.visible;
    },
    close() {
      this.visible = false;
      this.$emit('exit');
    },
    details(emoji, select) {
      this.last = emoji;

      if (select) {
        const current = emoji.data;

        this.$emit('select', current);

        console.log('$emoji:current', emoji);

        // this.visible = false;

        if (this.history.indexOf(emoji) === -1) {
          this.history = this.history.filter((history) => history.data !== current).slice(0, 47);

          this.history.unshift(emoji);

          localStorage.historyEmojis = JSON.stringify(this.history);
        }
      }
    },
    search() {
      if (this.timeout) clearTimeout(this.timeout);

      this.searching = true;

      this.timeout = setTimeout(() => {
        const query = this.query.toLowerCase();

        let results = [];

        this.emojis.forEach((item) => {
          item.forEach((emoji) => {
            if (emoji.title.toLowerCase().indexOf(query) !== -1) {
              results.push(emoji);
            }
          });
        });

        this.results = results;

        this.searching = false;

        results = null;
      }, 200);
    },
  },
};
</script>

<style scoped>
*, :after, :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*, ::after, ::before {
  box-sizing: border-box;
}

button {
  color: inherit;
  cursor: pointer;
  border: none;
  padding: 0;
  outline: none;
  background: transparent;
}

.emojis {
  position: relative;
  display: inline-block;
}

.emojis * {
  letter-spacing: normal !important;
}

.emojis > .popup-box-container > button {
  color: #a6a6a6;
}

.emojis > .popup-box-container > button:disabled {
  opacity: .4;
}

.emojis > .popup-box-container > button > svg {
  width: 16px;
}

.emojis__overlay {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: transparent;
  z-index: 100;
  display: none;
}

.emojis__widget {
  /* box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);  */
  box-shadow: 0 5px 25px 9px rgba(0, 0, 0, 0.3);
  border: 1px solid #c0c0c0;
  background: #fff;
  width: 305px;
  overflow: hidden;
  position: absolute;
  z-index: 1000;
  right: 0;
  display: none;
}

.emojis__widget.__left {
  left: 0;
}

.emojis__widget.__right {
  left: 0;
}

.emojis__widget.__top {
  top: 0;
}

.emojis__widget.__bottom {
  bottom: 0;
}

.emojis__overlay.active,
.emojis__widget.active {
  display: block;
}

.emojis header,
.emojis footer,
.emojis__category {
  height: 42px;
}

.emojis__area {
  height: 245px;
  overflow: auto;
  overflow-x: hidden;
  background: #f9f0f0;
  text-align: left;
}

.emojis__area h3 {
  text-align: center;
  color: #a7a8a8;
}

.emojis__container {
  display: none;
  padding: 5px;
}

.emojis__container.active {
  display: block;
}

.emojis__container > button {
  padding: 5px;
  width: 12.5%;
  font-size: 14pt;
}

.emojis button {
  transition: background 0.15s ease-out;
}

.emojis__container > button:hover {
  background: #cfcfcf;
  border-radius: 4px;
}

.emojis header {
  border-bottom: thin solid #ccc;
}

.emojis header > input {
  display: block;
  height: 100%;
  width: 100%;
  padding: 10px;
  border: 2px solid transparent;
  outline: none;
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"/></svg>')
    270px center no-repeat;
  background-size: 20px;
}

.emojis header > input:active, .emojis header > input:focus {
  border-color: #fc0;
}

.emojis__category {
  overflow: hidden;
  border-bottom: 1px solid #c0c0c0;
}

.emojis__category > button {
  position: relative;
  height: 42px;
  width: 10%;
  color: #484848;
  float: left;
}

.emojis__category > button:hover {
  background: #efefef;
}

.emojis__category > button > svg {
  width: 50%;
}

.emojis__category > :after {
  background: currentColor;
  position: absolute;
  bottom: 1px;
  left: 0;
  width: 100%;
  height: 0;
  content: "";
  transition: height 0.15s ease-out;
}

.emojis__category > .active:after {
  height: 4px;
}

.emojis footer {
  border-top: thin solid #c0c0c0;
  font-size: 12pt;
}

.emojis footer > div {
  padding: 0 10px;
  font-size: 9pt;
}

.emojis footer input {
  border: none;
  padding: 0;
  margin: 0;
  width: 32px;
  height: 42px;
  text-align: center;
  background: transparent;
  vertical-align: middle;
}

/*
@media (max-width: 341px) {
  .emojis__overlay {
    background: rgba(0, 0, 0, 0.2);
  }

  .emojis__widget {
    min-height: 210px;
    min-width: 300px;
    height: 95%;
    width: 95%;
  }
}*/
</style>
